import React from 'react';
import { useIntl} from "react-intl";

const TextInput =  React.forwardRef((props,ref) => {
  const intl = useIntl();

  return (
    <>
      <label htmlFor={props.id}>
        {props.label}
        {props.optional && <i> ({intl.formatMessage({ id: "optional", defaultMessage: "Valfritt"})})</i>}
      </label>
      <div className={`${props.readOnly ? 'read-only' : null}`}>
      {
        props.textArea ? 
        <textArea 
          name={props.name}
          id={props.id}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          readOnly={props.readOnly}
          disabled={props.readOnly}
          ref={ref}
          maxLength="160"
          >
        </textArea> :
        <input
          type='text'
          name={props.name}
          id={props.id}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          readOnly={props.readOnly}
          disabled={props.readOnly}
          ref={ref}
        />
      }
      </div>
    </>
  );
});

export default TextInput;
