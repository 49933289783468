import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { IntlProvider } from 'react-intl';
import sv from './translation/sv.json';
import { setConfig } from "./config";

import * as serviceWorker from './serviceWorker';

const languages = {
  "sv": sv
};

setConfig().then((conf) => { init(conf); })

const lang = getUrlParam('langKey', 'sv');
const init = (conf) => {
  ReactDOM.render(<IntlProvider locale={"sv"} messages={languages[lang] || languages["sv"]}> <App /></IntlProvider>, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}

function getUrlVars() {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });

  return vars;
}

function getUrlParam(parameter, defaultvalue) {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }

  return urlparameter;
}