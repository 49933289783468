import React from 'react';
import { FormattedMessage } from 'react-intl';

const SafetyValves = props => {
  return (
    <>
      <label className='label'>
        <FormattedMessage id="shouldmountsafetyvessel" defaultMessage="Skall säkerhetsventiler monteras direkt på respektive panna?"/>
      </label>
      <div className='input_flex_row '>
        <label htmlFor='svyid' className='container radio_button'>
          <FormattedMessage id="yes" defaultMessage="Ja"/>
          <input
            type='radio'
            name='safety-valve_direct'
            id='svyid'
            value='yes'
            checked={props.safetyValveDirect === 'yes'}
            onChange={props.onChange}
          />
          <span className='checkmark'></span>
        </label>
        <label htmlFor='svnid' className='container radio_button'>
        <FormattedMessage id="no" defaultMessage="Nej"/>
          <input
            type='radio'
            name='safety-valve_direct'
            id='svnid'
            value='no'
            checked={props.safetyValveDirect === 'no'}
            onChange={props.onChange}
          />
          <span className='checkmark'></span>
        </label>
      </div>
    </>
  );
}

export default SafetyValves;