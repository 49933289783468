import React from 'react';
import TextInput from '../shared/textInput';
import NumberInputWithInfo from '../shared/numberInputWithInfo';
import Temperatures from './formItems/temperatures';
import Constructions from './formItems/constructions';
import SafetyResults from './formItems/safetyResults';
import { FormattedMessage } from 'react-intl';

const SafetyCategoryForm = props => {
  return (
    <>
      {props.visible && (
        <div>
          <fieldset>
            <TextInput
              label={<FormattedMessage id="nameofobject" defaultMessage="Namn på kalkyl/objekt"/>}              
              id='sonid'
              name='safety_object_name'
              placeholder='Objekt 1'
              value={props.objectName}
              onChange={props.onChange}
               />
          </fieldset>

          <Constructions
            addConstruction={props.addConstruction}
            deleteConstruction={props.deleteConstruction}
            type={props.constructionType}
            constructions={props.constructions}
            heatPumpAmount={props.heatPumpAmount}
            safetyValveDirect={props.safetyValveDirect}
            zeroFlowApproved={props.zeroFlowApproved}
            typeOfSystem={props.typeOfSystem}
            errors={props.errors}
            onChange={props.onChange} />

          <fieldset>
            <NumberInputWithInfo
              id='sshid'
              label={<FormattedMessage id="staticheight" defaultMessage="Statisk höjd (Hst)"/>}
              alt='Höjd infoikon'
              infoText={<FormattedMessage id="staticheightinfo" defaultMessage="Avståndet från expansionskärlet upp till anläggningens högsta punkt. Vid ex borrhål är det endast höjd ovan expansionskärl som tas med."/>}
              name='safety_static_height'
              value={props.staticHeight}
              errors={props.errors}
              suffix="static_height"
              onChange={props.onChange}
               />
          </fieldset>

          <fieldset>
            <NumberInputWithInfo
              id='sopid'
              label={<FormattedMessage id="openingpressure" defaultMessage="Öppningstryck (Psv)"/>}
              alt='Valfritt öppningstryck infoikon'
              infoText={<FormattedMessage id="openingpressureinfo" defaultMessage="Om du inte anger något värde kommer Varmber föreslå ett öppningstryck."/>}
              placeholder='Välj tryck'
              name='safety_opening_pressure'
              value={props.openingPressure}
              errors={props.errors}
              suffix="pressure"
              onChange={props.onChange}
              step={0.5}
               />
          </fieldset>

          <fieldset>
            <Temperatures
              topSupplyTemp={props.topSupplyTemp}
              lowestReturnTemp={props.lowestReturnTemp}
              errors={props.errors}
              suffix="return_temperature"
              onChange={props.onChange}
            />
          </fieldset>

          

          <SafetyResults results={props.results} />
        </div>
      )}
    </>
  );
}

export default SafetyCategoryForm;