import React from 'react';
import NumberInput from '../../shared/numberInput';

const Temperatures = props => {
  return (
    <>
      <fieldset>
        <NumberInput
          id={'safety_supply-temp'}
          label='Tilloppstemp.'
          name={'safety_supply-temp'}
          value={props.topSupplyTemp}
          errors={props.errors}
          suffix='inlet_temperature'
          onChange={props.onChange}
           />
      </fieldset>
      <fieldset>
        <NumberInput
          id={'safety_return-temp'}
          label='Returtemp.'
          name={'safety_return-temp'}
          value={props.lowestReturnTemp}
          errors={props.errors}
          suffix='return_temperature'
          onChange={props.onChange}
           />
      </fieldset>
    </>
  );
}

export default Temperatures;