const safetyFunctions = {
  getResult() {
    let isBoiler = this.state.constructionType === "1";
    let url = new URL(this.state.rootUrl + 'varmber/safety');
    let params = {
      constructions: JSON.stringify(this.state.constructions.map((c) => ({
        effectValue: c.effect || "0",
        pipeType: c.pipeType ? c.pipeType : "-1",
        boilerType: isBoiler ? c.boilerType : "-1"
      }))),
      staticHeight: this.state.safetyStaticHeight,
      topSupplyTemp: this.state.safetyTopSupplyTemp,
      lowestReturnTemp: this.state.safetyLowestReturnTemp,
      constructionType: this.state.constructionType,
      heatPumpAmount: this.state.heatPumpAmount,
      safetyValveDirect: toBoolean(this.state.safetyValveDirect),
      zeroFlowApproved: toBoolean(this.state.zeroFlowApproved),
      typeOfSystem: toTypeOfSystemEnum(this.state.typeOfSystem),
      vesselType: this.state.vesselType,
      valveKitInVesselResult: this.hasValveKitInVesselResult(),
      openingPressure: this.state.systemSafetyOpeningPressure ? "" : this.state.safetyOpeningPressure
    }

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    fetch(url).then(response => {
      if (response.status[0] === '4' || response.status[0] === '5') {
        throw Error();
      }

      return response.json();
    }).then(response => {
      this.setState({
        safetyResults: response, safetyOpeningPressure: response.openingPressure, systemSafetyOpeningPressure: response.openingPressure.toString() !== params.openingPressure.toString(),
        emailSent: false,
        emailSentError: false
      }, () => this.clearError('fetch_safety'));
    }).catch(() => {
      this.setState(
        { safetyResults: null },
        () => this.setError('fetch_safety', 'Kunde inte hämta produktförslag, vänligen försök igen')
      );
    }).finally(() => { this.setState({ working: false }); })
  },

  requestResult() {
    this.setState(prevState => {
      let constructions = [...prevState.constructions];
      constructions.forEach(c => {
        c.sentEffect = c.effect || "0";
        c.sentPipeType = c.pipeType;
        c.sentBoilerType = c.boilerType;
      })

      return {
        working: true,
        constructions: constructions,
        sentTopSupplyTemp: getTopSupplyTemp(prevState.circuits),
        sentLowestReturnTemp: getLowestReturnTemp(prevState.circuits),
        sentStaticHeight_safety: prevState.staticHeight,
        sentOpeningPressure_safety: prevState.openingPressure,
        sentConstructionType: prevState.constructionType,
        sentHeatPumpAmount: prevState.heatPumpAmount,
        sentSafetyValveDirect: prevState.safetyValveDirect,
        sentZeroFlowApproved: prevState.zeroFlowApproved,
        sentVesselType: prevState.vesselType
      }
    }, this.getSafetyResult())
  },
  hasValveKitInVesselResult() {
    return Boolean(this.state.vesselResults && this.state.vesselResults.alternativeOne ? (this.state.alternativeOneSelected ? this.state.vesselResults.alternativeOne.accessories.find(x => x.articleNumber === "AT 1844A10" || x.articleNumber.includes("8374")) : this.state.vesselResults.alternativeTwo.accessories.find(x => x.articleNumber === "AT 1844A10" || x.articleNumber.includes("8374"))) : null)
  },

  nothingMissing() {
    let missing = false;
    if (!this.state.safetyStaticHeight || !this.state.safetyLowestReturnTemp || !this.state.safetyTopSupplyTemp) {
      missing = true;
    } else {
      switch (this.state.constructionType) {
        case '1': {
          for (var x = 0; x < this.state.constructions.length; x++) {
            let c = this.state.constructions[x];
            if (!c.effect) {
              missing = true;
            }
          }
          break;
        }
        case '2': {
          for (var y = 0; y < this.state.constructions.length; y++) {
            let c = this.state.constructions[y];
            if (!c.effect) {
              missing = true;
            }
          }
          break;
        }
        case '3': { break; } // Has default value, so can't be missing
        default:
          missing = true;
        //  console.error('Unknown construction type');
      }
    }

    return missing === false;
  },

  noErrors() {
    let hasErrors =
      this.state.errors['safety_static_height'] ||
      this.state.errors['safety_opening_pressure'] ||
      this.state.errors['circuit_supply-temp_1'] ||
      this.state.errors['circuit_supply-temp_2'] ||
      this.state.errors['circuit_supply-temp_3'] ||
      this.state.errors['circuit_return-temp_1'] ||
      this.state.errors['circuit_return-temp_2'] ||
      this.state.errors['circuit_return-temp_3'] ||
      this.state.errors['constructions_type'] ||
      this.state.errors['heat-pump_amount'] ||
      this.state.errors['construction_effect_1'] ||
      this.state.errors['construction_effect_2'] ||
      this.state.errors['construction_effect_3'] ||
      this.state.errors['construction_pipe-type_1'] ||
      this.state.errors['construction_pipe-type_2'] ||
      this.state.errors['construction_pipe-type_3'] ||
      this.state.errors['construction_boiler-type_1'] ||
      this.state.errors['construction_boiler-type_2'] ||
      this.state.errors['construction_boiler-type_3'] ||
      this.state.errors['safety-valve_direct'] ||
      this.state.errors['zero_flow_approved'] ||
      this.state.errors['safety_supply-temp'] ||
      this.state.errors['safety_return-temp'];

    return !hasErrors;
  },

  requestValuesHaveChanged() {
    let changed = false;
    if (
      this.state.sentTopSupplyTemp && getTopSupplyTemp(this.state.circuits) !== this.state.sentTopSupplyTemp
    ) {
      changed = true;
    } else if (
      this.state.sentLowestReturnTemp && getLowestReturnTemp(this.state.circuits) !== this.state.sentLowestReturnTemp
    ) {
      changed = true;
    } else if (
      this.state.sentStaticHeight_safety && this.state.staticHeight !== this.state.sentStaticHeight_safety
    ) {
      changed = true;
    } else if (
      (this.state.sentOpeningPressure_safety && this.state.openingPressure !== this.state.sentOpeningPressure_safety) ||
      (!this.state.sentOpeningPressure_safety && this.state.openingPressure)
    ) {
      changed = true;
    } else if (this.state.sentConstructionType && this.state.constructionType !== this.state.sentConstructionType) {
      changed = true;
    } else if (this.state.sentHeatPumpAmount && this.state.heatPumpAmount !== this.state.sentHeatPumpAmount) {
      changed = true;
    } else if (this.state.sentSafetyValveDirect && this.state.safetyValveDirect !== this.state.sentSafetyValveDirect) {
      changed = true;
    } else if (this.state.sentZeroFlowApproved && this.state.zeroFlowApproved !== this.state.sentZeroFlowApproved) {
      changed = true;
    } else if (this.state.sentVesselType && this.state.vesselType !== this.state.sentVesselType) {
      changed = true;
    } else {
      this.state.constructions.forEach(c => {
        if ((c.sentEffect && c.effect !== c.sentEffect) || (!c.sentEffect && c.effect)) {
          changed = true;
        } else if ((c.sentPipeType && c.pipeType !== c.sentPipeType) || (!c.sentPipeType && c.pipeType)) {
          changed = true;
        } else if ((c.sentBoilerType && c.boilerType !== c.sentBoilerType) || (!c.sentBoilerType && c.boilerType)) {
          changed = true;
        }
      });
    }

    return changed;
  },

  tryFetchSafety() {
    if (this.nothingMissing() && this.noErrorsForSafety()) {

      this.requestSafetyResult();
    }
  }
};

export default safetyFunctions;

export const getTopSupplyTemp = cs => {
  let result = parseInt(cs[0].supplyTemp, 10);
  for (let i = 1; i < cs.length; i++) {
    let circuit = cs[i];
    let st = parseInt(circuit.supplyTemp, 10);
    if (st > result) {
      result = st;
    }
  }

  return result;
}

export const getLowestReturnTemp = cs => {
  let result = parseInt(cs[0].returnTemp, 10);
  for (let i = 1; i < cs.length; i++) {
    let circuit = cs[i];
    let rt = parseInt(circuit.returnTemp, 10);
    if (rt < result) {
      result = rt;
    }
  }

  return result;
}

const toBoolean = yesOrNo => {
  if (yesOrNo === 'yes') return '1';
  if (yesOrNo === 'no') return '0';
  console.error('Can only turn yes/no to boolean')
}

const toTypeOfSystemEnum = openOrClosed => {
  if (openOrClosed === 'closed') return 1;
  if (openOrClosed === 'open') return 2;
  console.error('Can only turn openOrClosed to boolean')
}