const types = [
  'DN 25',
  'DN 32',
  'DN 40',
  'DN 50',
  'DN 65',
  'DN 80',
  'DN 100',
  'DN 125',
  'DN 150',
  'DN 200',
  'DN 250',
  'DN 300',
  'DN 350',
  'DN 400'
];

export default types;