import React from 'react';
import refreshWhiteIcon from '../../../icons/refresh_white.svg';
import resultIcon from '../../../icons/result.svg';
import { useIntl } from "react-intl";

const Results = props => {
  const intl = useIntl();

  return (
    <>
      <h2>{intl.formatMessage({ id: "result", defaultMessage: "Resultat" })}</h2>
      {!props.values && (
        <div className='results no-results'>
          <div>
            <img src={resultIcon} alt='Inget resultat ikon' />
            <span>
              {intl.formatMessage({ id: "resultinfo", defaultMessage: "Fyll i uppgifterna ovan för att få förslag på expansionskärl här" })}
            </span>
          </div>
        </div>
      )}
      {props.values && (!props.values.alternativeOne || !props.values.alternativeOne.products) &&
        <div className='results no-results'>
          <div>
            <img src={resultIcon} alt="Inget resultat ikon" />
            <span>
                {intl.formatMessage({ id: "nosearchhit", defaultMessage: 'Ingen sökträff på passande utrustning, vänligen kontakta Armatec för fortsatt dialog via nedan ',})} <a href='#form-anchor'>formulär</a>.
              </span>
          </div>
        </div>
      }
      {props.values && (props.values.alternativeOne && props.values.alternativeOne.products) && (
        <>
          <Alternative
            label={(props.values.alternativeTwo && props.values.alternativeTwo.products ? intl.formatMessage({ id: "alternative", defaultMessage: "Alternativ" }) + ' 1' : '')}
            alternative={props.values.alternativeOne}
            selected={props.alternativeOneSelected}
            onSelect={props.selectAlternativeOne} />
          {props.values.alternativeTwo && props.values.alternativeTwo.products &&
            <Alternative
              label={intl.formatMessage({ id: "alternative", defaultMessage: "Alternativ" }) + '  2'}
              alternative={props.values.alternativeTwo}
              selected={props.alternativeTwoSelected}
              onSelect={props.selectAlternativeTwo} />
          }
          <button type='button' className={'btn-primary'} onClick={props.again}>
            <img src={refreshWhiteIcon} alt='Rensa formulär ikon' />
            {intl.formatMessage({ id: "createnewestimation", defaultMessage: "Gör ny beräkning" })}
          </button>
        </>
      )}
    </>
  )
}

const Alternative = props => {
  const intl = useIntl();

  const anyAlternativeHasOperatingPressure = props.alternative && props.alternative.products && props.alternative.products.some(x => x.maxOperatingPressure);
  const anyAlternativeHasPn = props.alternative && props.alternative.products && props.alternative.products.some(x => x.pressureNominal);

  return (
    <div className={props.selected ? 'table-container' : 'table-container clickable'} onClick={props.onSelect}>
      {props.label &&
        <button onClick={props.onSelect} className={'alternative-button'}>
          <h3>{props.label}</h3>
        </button>
      }
      <div className='alternative'>
        {props.selected && (
          <>
            <table className="alternative-table">
              <thead>
                <tr>
                  <th />
                  <th>{intl.formatMessage({ id: "articlenumber", defaultMessage: "Artikelnummer" })}</th>
                  <th>RSK</th>
                  <th>{intl.formatMessage({ id: "description", defaultMessage: "Beskrivning" })}</th>
                  <th>{intl.formatMessage({ id: "volume", defaultMessage: "Volym" })} (l)</th>
                  {anyAlternativeHasOperatingPressure && <th>{intl.formatMessage({ id: "maxoperatingpressure", defaultMessage: "Max drifttryck" })} (bar)</th>}
                  {anyAlternativeHasPn && <th>{intl.formatMessage({ id: "pn", defaultMessage: "PN" })} (bar)</th>}
                </tr>
              </thead>
              <tbody>
                {props.alternative && props.alternative.products && props.alternative.products.map((p, i) => (
                  <tr key={'alternative_' + props.index + '_product_' + i}>
                    <td>
                      {/* TODO: remove placeholder image */}
                      {p.image &&  <img src={p.image} alt='Bild på produkt' />}
                    </td>
                    <td className="articleNumber"><a href={p.link} rel="noopener noreferrer" target="_blank">{p.articleNumber}</a></td>
                    <td>{p.rsk} </td>
                    <td className="description"><p>{p.description}</p></td>
                    <td>{p.vesselVolume}</td>
                    {anyAlternativeHasOperatingPressure && <td>{p.maxOperatingPressure}</td>}
                    {anyAlternativeHasPn && <td>{p.pressureNominal}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
            {props.alternative && props.alternative.accessories && (props.alternative.accessories.length > 0) && (
              <div className={'addons'}>
                <h3>{intl.formatMessage({ id: "accessories", defaultMessage: "Tillbehör" })}</h3>
                <table className="alternative-table">
                  <thead>
                    <tr>
                      <th />
                      <th>{intl.formatMessage({ id: "articlenumber", defaultMessage: "Artikelnummer" })}</th>
                      <th>RSK</th>
                      <th>{intl.formatMessage({ id: "description", defaultMessage: "Beskrivning" })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.alternative && props.alternative.accessories.map((a, i) => (
                      <tr key={'alternative_' + props.index + '_accessory_' + i}>
                        <td>
                          {/* TODO: remove placeholder image */}
                    {a.image && <img src={a.image}  alt='Bild på tillbehörsprodukt' /> }
                        </td>
                        <td className='articleNumber'><a href={a.link} rel="noopener noreferrer" target="_blank">{a.articleNumber}</a></td>
                        <td>{a.rsk}</td>
                        <td><p>{a.description}</p></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Results