import React from 'react';
import FormError from './formError';

const Select = props => {
    let startInd = props.startIndex || 0;
    return (
        <>
            <label htmlFor={props.id}>{props.label}</label>
            <select
                name={props.name}
                id={props.id}
                value={props.value ? props.value : -1}
                onChange={props.onChange}
                aria-describedby={props.errors && props.errors[props.name] ? props.name + '_error' : ''}
            >
                {props.placeholder && (
                    <option value={-1} disabled>
                        {props.placeholder}
                    </option>
                )}
                {props.options.map((o, i) => (
                    <option value={i + startInd + 1} key={props.id + '_option_' + (i + startInd)}>
                        {o}
                    </option>
                ))}
            </select>
            {props.errors && <FormError errors={props.errors} name={props.name} />}
        </>
    );
};

export default Select;
