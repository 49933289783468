import React from 'react';
import { FormattedMessage } from 'react-intl';

const TypeOfSystem = props => {
  return (
    <>
      <label className='label'>
        <FormattedMessage id="typeofsystem" defaultMessage="Typ avv system?"/>
      </label>
      <div className='input_flex_row '>
        <label htmlFor='tosoid' className='container radio_button'>
        <FormattedMessage id="open" defaultMessage="Öppet"/>
          <input
             type='radio'
             name='type_of_system'
             id='tosoid'
             value='open'
             checked={props.typeOfSystem === 'open'}
             onChange={props.onChange}
          />
          <span className='checkmark'></span>
        </label>
        <label htmlFor='tosyid' className='container radio_button'>
        <FormattedMessage id="closed" defaultMessage="Stängt"/>
          <input
            type='radio'
            name='type_of_system'
            id='tosyid'
            value='closed'
            checked={props.typeOfSystem === 'closed'}
            onChange={props.onChange}
          />
          <span className='checkmark'></span>
        </label>
      </div>
    </>
  );
}

export default TypeOfSystem;