import React from 'react';
import resultIcon from '../../../icons/result.svg';
import { useIntl } from 'react-intl';

const SafetyResults = (props) => {
    const intl = useIntl();
    let resultView;

    if (!props.results) {
        resultView = (
            <div className="results  no-results">
                <div>
                    <img src={resultIcon} alt="Inget resultat ikon" />
                    <span>
                        {intl.formatMessage({
                            id: 'resultsafetyinfo',
                            defaultMessage: 'Fyll i uppgifterna ovan för att få förslag på expansionskärl här',
                        })}
                    </span>
                </div>
            </div>
        );
    } else if (!props.results.products || props.results.products.length === 0) {
        resultView = (
            <div className="results no-results">
                <div>
                    <img src={resultIcon} alt="Inget resultat ikon" />
                    <span>
                        {intl.formatMessage({
                            id: 'nosearchhitsafety',
                            defaultMessage:
                                'Ingen sökträff på passande utrustning, vänligen kontakta Armatec för fortsatt dialog via nedan ',
                        })}
                        <a href='#form-anchor'>formulär</a>.
                    </span>
                </div>
            </div>
        );
    } else {
        resultView = <ResultTable results={props.results} />;
    }

    return (
        <>
            <h2>{intl.formatMessage({ id: 'result', defaultMessage: 'Resultat' })}</h2>
            {resultView}
        </>
    );
};

const ResultTable = (props) => {
    const intl = useIntl();

    return (
        <div className="table-container">
            <table className="alternative-table">
                {props.results && (
                    <>
                        <thead>
                            <tr>
                                <th></th>
                                <th>{intl.formatMessage({ id: 'articlenumber', defaultMessage: 'Artikelnummer' })}</th>
                                <th>RSK</th>
                                <th>{intl.formatMessage({ id: 'description', defaultMessage: 'Beskrivning' })}</th>
                                <th>DN</th>
                                <th>
                                    {intl.formatMessage({ id: 'openingpressure', defaultMessage: 'Öppningstryck (Psv)' })}
                                </th>
                                <th>{intl.formatMessage({ id: 'comment', defaultMessage: 'Kommentar' })}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.results &&
                                props.results.products.map((p, i) => (
                                    <React.Fragment key={i}>
                                        <tr key={'alternative_' + props.index + '_product_' + i}>
                                            <td>
                                                {p.image && <img src={p.image} alt={'bild på ' + p.articleNumber} />}
                                            </td>
                                            <td className="articleNumber">
                                                <a href={p.link} rel="noopener noreferrer" target="_blank">
                                                    {p.articleNumber}
                                                </a>
                                            </td>
                                            <td>{p.rsk} </td>
                                            <td className="description">{p.description}</td>
                                            <td>{p.dn}</td>
                                            <td>{p.openingPressure ? `${p.openingPressure} bar` : ''} </td>
                                            <td>{p.comment} </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                        </tbody>
                    </>
                )}
            </table>

         {props.results.safetyAccessories && props.results.safetyAccessories.length > 0 && ( <div className={'addons'}>
                        <h3>
                            Tillbehör
                        </h3>  <table className="alternative-table">
                
                    <>
                        <thead>
                            <tr>
                                <th></th>
                                <th>{intl.formatMessage({ id: 'articlenumber', defaultMessage: 'Artikelnummer' })}</th>
                                <th>RSK</th>
                                <th>{intl.formatMessage({ id: 'description', defaultMessage: 'Beskrivning' })}</th>
                                <th>{intl.formatMessage({ id: 'comment', defaultMessage: 'Kommentar' })}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.results &&
                                props.results.safetyAccessories.map((p, i) => (
                                    <React.Fragment key={i}>
                                        <tr key={'alternative_' + props.index + '_product_' + i}>
                                            <td>
                                                {p.image && <img src={p.image} alt={'bild på ' + p.articleNumber} />}
                                            </td>
                                            <td className="articleNumber">
                                                <a href={p.link} rel="noopener noreferrer" target="_blank">
                                                    {p.articleNumber}
                                                </a>
                                            </td>
                                            <td>{p.rsk} </td>
                                            <td>{p.description}</td>
                                            <td>{p.comment} </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                        </tbody>
                    </>
                
            </table></div>)}

            {props.results.alternatives &&
                props.results.alternatives.map((alternative) => (
                    <div key={alternative.mainArticleNumber} className={'addons'}>
                        <h3>
                            {intl.formatMessage({ id: 'alternative', defaultMessage: 'Altern' })} för{' '}
                            {' ' + alternative.mainArticleNumber}
                        </h3>
                        <table className="alternative-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        {intl.formatMessage({ id: 'articlenumber', defaultMessage: 'Artikelnummer' })}
                                    </th>
                                    <th>RSK</th>
                                    <th>{intl.formatMessage({ id: 'description', defaultMessage: 'Beskrivning' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {alternative.alternatives &&
                                    alternative.alternatives.map((a, i) => (
                                        <tr key={'alternative_' + props.index + '_accessory_' + i}>
                                            <td>
                                                {a.image && <img src={a.image} alt={'bild på ' + a.articleNumber} />}
                                            </td>
                                            <td className="articleNumber">
                                                <a href={a.link} rel="noopener noreferrer" target="_blank">
                                                    {a.articleNumber}
                                                </a>
                                            </td>
                                            <td>{a.rsk}</td>
                                            <td>
                                                <p>{a.description}</p>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                ))}
        </div>
    );
};

export default SafetyResults;
