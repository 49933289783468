import React from 'react';
import Report from './report';
import SafetyCategory from './safety/safetyCategory';
import './spin.css';
import VesselCategory from './vessels/vesselCategory';
import volumeFunctions from './umbrella/volume';
import vesselFunctions from './umbrella/vessel';
import safetyFunctions from './umbrella/safety';
import { getTopSupplyTemp, getLowestReturnTemp } from './umbrella/safety';
import { FormattedMessage, injectIntl } from 'react-intl';
import { debounce } from './shared/debounce';

const blurStyle = {
    filter: 'blur(6px)',
    WebkitFilter: 'blur(6px)',
};

const waterConcentrationLimits = { min: '100', max: '100' };
const waterTemperatureLimits = { min: '1', max: '200' };

const ethanolConcentrationLimits = { min: '0', max: '30' };

const nonWaterConcentrationLimits = { min: '0', max: '50' };
const nonWaterReturnTemperatureLimits = { min: '-30', max: '110' };

const ethyleneGlycolSupplyTemperatureLimits = { min: '-30', max: '80' };
const propyleneGlycolSupplyTemperatureLimits = { min: '-30', max: '110' };
const ethanolSupplyTemperatureLimits = { min: '-30', max: '40' };

const safetyTemperatureLimits = { min: '-30', max: '110' };

const initialState = {
    // Visual
    working: false,
    showVessel: false,
    showSafety: false,
    manualVolumeCalculation: true,
    culvertExpanded: false,
    manualAddendExpanded: false,
    systemSafetyOpeningPressure: false,
    systemOpeningPressure: false,
    errorEmail: false,
    emailSent: false,
    emailSentError: false,
    emailIsSending: false,

    errors: {},
    staticHeightLimits: { min: 1, max: 80 },
    openingPressureLimits: { min: 0, max: 10 },
    fluidConcentrationLimits: waterConcentrationLimits,
    supplyTempLimits: waterTemperatureLimits,
    returnTempLimits: waterTemperatureLimits,

    // Vessel
    selectedSystemType: 'heating',
    fluidType: '1',
    fluidConcentration: '100',
    circuits: [{ effect: '', supplyTemp: '', returnTemp: '', type: null, volume: '' }],
    pipeType: null,
    pipeLength: '',
    manualAddend: '',
    totalSystemVolume: '',
    vesselType: '1',

    records: {
        expansion: '',
        prePressure: '',
        expansionVolume: '',
        openingPressure: '',
        fillingPressure: '',
    },
    vesselResults: null,
    alternativeOneSelected: false,
    alternativeTwoSelected: false,

    // Shared
    objectName: '',
    staticHeight: '',
    openingPressure: '',
    topSupplyTemp: '',
    lowestReturnTemp: '',

    // Safety
    safetyObjectName: '',
    safetyStaticHeight: '',
    safetyOpeningPressure: '',
    safetyTopSupplyTemp: '',
    safetyLowestReturnTemp: '',
    constructionType: null,
    constructions: [{ effect: '', pipeType: '', boilerType: '1' }],
    safetyValveDirect: 'no',
    zeroFlowApproved: 'no',
    typeOfSystem: 'closed',
    heatPumpAmount: '1',
    safetyResults: null,

    selectedEmailType: 'offer',
    email: '',
    offerName: '',
    offerCompany: '',
    offerCustomerNumber: '',
    offerLocation: '',
    offerPhoneNumber: '',
    offerInformation: '',
    sentNumberOfCircuits_volume: false,
    sentPipeType: false,
    sentPipeLength: false,
    sentManualAddend: false,
};

class Umbrella extends React.Component {
    constructor(props) {
        super(props);

        this.objectNameRef = React.createRef();
        initialState.rootUrl =
            props.env === 'production' ? window.location.origin + '/api/' : 'https://localhost:5001/';
        this.state = {
            ...initialState,
            circuits: [...initialState.circuits.map((x) => ({ ...x }))],
        };

        this.setFluidTypeAndLimits = this.setFluidTypeAndLimits.bind(this);
        this.setFluidConcentration = this.setFluidConcentration.bind(this);
        this.handleCircuitEffectChange = this.handleCircuitEffectChange.bind(this);
        this.setCircuitEffect = this.setCircuitEffect.bind(this);
        this.setCircuitSupplyTemp = this.setCircuitSupplyTemp.bind(this);
        this.setCircuitReturnTemp = this.setCircuitReturnTemp.bind(this);
        this.setCircuitType = this.setCircuitType.bind(this);
        this.setStaticHeight = this.setStaticHeight.bind(this);
        this.setHeatingOrCooling = this.setHeatingOrCooling.bind(this);
        this.setOpeningPressure = this.setOpeningPressure.bind(this);
        this.setPipeLength = this.setPipeLength.bind(this);
        this.addCircuit = this.addCircuit.bind(this);
        this.deleteCircuit = this.deleteCircuit.bind(this);
        this.setManualVolumeCalculation = this.setManualVolumeCalculation.bind(this);
        this.toggleCulvertExpansion = this.toggleCulvertExpansion.bind(this);
        this.toggleManualAddendExpanded = this.toggleManualAddendExpanded.bind(this);
        this.getTemperatureRatioErrors = this.getTemperatureRatioErrors.bind(this);
        this.noErrorsForVessels = vesselFunctions.noErrorsForVessels.bind(this);
        this.noErrorsForVolume = volumeFunctions.noErrorsForVolume.bind(this);
        this.vesselRequestValuesHaveChanged = vesselFunctions.vesselRequestValuesHaveChanged.bind(this);
        this.volumeRequestValuesHaveChanged = volumeFunctions.volumeRequestValuesHaveChanged.bind(this);
        this.hasCompleteCircuitsForVessels = vesselFunctions.hasCompleteCircuitsForVessels.bind(this);
        this.hasCompleteCircuitsForVolume = volumeFunctions.hasCompleteCircuitsForVolume.bind(this);
        this.vesselRequestHasBeenSent = vesselFunctions.vesselRequestHasBeenSent.bind(this);
        this.volumeRequestHasBeenSent = volumeFunctions.volumeRequestHasBeenSent.bind(this);
        this.tryFetch = debounce(vesselFunctions.tryFetch.bind(this), 500);
        this.getVolume = volumeFunctions.getVolume.bind(this);
        this.requestVolume = volumeFunctions.requestVolume.bind(this);
        this.getVesselResult = vesselFunctions.getVesselResult.bind(this);
        this.requestVesselResult = vesselFunctions.requestVesselResult.bind(this);
        this.selectAlternativeOne = this.selectAlternativeOne.bind(this);
        this.selectAlternativeTwo = this.selectAlternativeTwo.bind(this);
        this.onVesselChange = this.onVesselChange.bind(this);

        this.setTemperatureErrors = this.setTemperatureErrors.bind(this);
        this.setError = this.setError.bind(this);
        this.clearError = this.clearError.bind(this);
        this.clear = this.clear.bind(this);
        this.onChange = this.onChange.bind(this);
        this.send = this.send.bind(this);
        this.onSafetyChange = this.onSafetyChange.bind(this);
        this.tryFetchSafety = debounce(safetyFunctions.tryFetchSafety.bind(this), 500);
        this.nothingMissing = safetyFunctions.nothingMissing.bind(this);
        this.noErrorsForSafety = safetyFunctions.noErrors.bind(this);
        this.safetyRequestValuesHaveChanged = safetyFunctions.requestValuesHaveChanged.bind(this);
        this.hasValveKitInVesselResult = safetyFunctions.hasValveKitInVesselResult.bind(this);
        this.requestSafetyResult = safetyFunctions.requestResult.bind(this);
        this.getSafetyResult = safetyFunctions.getResult.bind(this);
        this.handleConstructionEffectChange = this.handleConstructionEffectChange.bind(this);
        this.setConstructionEffect = this.setConstructionEffect.bind(this);
        this.setConstructionPipeType = this.setConstructionPipeType.bind(this);
        this.setConstructionBoilerType = this.setConstructionBoilerType.bind(this);
        this.addConstruction = this.addConstruction.bind(this);
        this.deleteConstruction = this.deleteConstruction.bind(this);
        this.getSendToCartUrl = this.getSendToCartUrl.bind(this);
    }

    selectAlternativeOne() {
        this.setState({ alternativeOneSelected: true, alternativeTwoSelected: false });
    }

    selectAlternativeTwo() {
        this.setState({ alternativeOneSelected: false, alternativeTwoSelected: true });
    }

    getFluidName(fluidType) {
        switch (fluidType) {
            case '1':
                return this.props.intl.formatMessage({ id: 'water', defaultMessage: 'vatten' });
            case '2':
                return this.props.intl.formatMessage({ id: 'ethyleneglycol', defaultMessage: 'ethylenglykol' });
            case '3':
                return this.props.intl.formatMessage({ id: 'propylene', defaultMessage: 'Propylenglykol' });
            case '4':
                return this.props.intl.formatMessage({ id: 'ethanol', defaultMessage: 'Etanol' });
            default:
                console.error('Cannot get name for unknown fluid type');
        }
    }
    getVesselTypeName() {
        switch (this.state.vesselType) {
            case '1':
                return this.props.intl.formatMessage({
                    id: 'closedvesselprepressure',
                    defaultMessage: 'Slutet kärl med förtryck',
                });
            case '2':
                return this.props.intl.formatMessage({
                    id: 'closedcompressvessel',
                    defaultMessage: 'Slutet kompressorkärl',
                });
            case '3':
                return this.props.intl.formatMessage({
                    id: 'openvesselwithpump',
                    defaultMessage: 'Öppet kärl med pump',
                });
            default:
                console.error('Cannot get name for unknown vessel type');
        }
    }

    getConstructionTypeName() {
        switch (this.state.constructionType) {
            case '1':
                return this.props.intl.formatMessage({ id: 'boiler', defaultMessage: 'panna' });
            case '2':
                return this.props.intl.formatMessage({ id: 'heatexchanger', defaultMessage: 'värmeväxlare' });
            case '3':
                return this.props.intl.formatMessage({ id: 'heatpump', defaultMessage: 'pump' });
            default:
                console.error('Cannot get name for unknown construction type');
        }
    }

    getBoilerTypeName(boilerType) {
        switch (boilerType) {
            case '1':
                return this.props.intl.formatMessage({ id: 'oilelectricitygas', defaultMessage: 'olja' });
            case '2':
                return this.props.intl.formatMessage({ id: 'solidfuel', defaultMessage: 'fastbränsle' });
            default:
                return this.props.intl.formatMessage({ id: 'oilelectricitygas', defaultMessage: 'olja' });
        }
    }

    addCircuit() {
        if (this.state.circuits.length < 3) {
            this.setState((prevState) => ({
                circuits: [
                    ...prevState.circuits,
                    { effect: '', supplyTemp: '', returnTemp: '', type: null, volume: '' },
                ],
            }));
        }
    }

    deleteCircuit(index) {
        if (index > 0) {
            this.setState(
                (prevState) => {
                    let c = [...prevState.circuits];
                    c.splice(index, 1);

                    return { circuits: c };
                },
                () => this.tryFetch(),
            );
        }
    }

    setManualVolumeCalculation(b) {
        if (b) {
            this.setState({ manualVolumeCalculation: b });
        } else {
            this.setState({ manualVolumeCalculation: b, totalSystemVolume: '' });
        }
    }

    toggleCulvertExpansion() {
        this.setState((prevState) => ({
            culvertExpanded: !prevState.culvertExpanded,
        }));
    }

    toggleManualAddendExpanded() {
        this.setState((prevState) => ({
            manualAddendExpanded: !prevState.manualAddendExpanded,
        }));
    }

    setFluidTypeAndLimits(value) {
        var concentrationLimits, supplyTempLimits, returnTempLimits;
        switch (value) {
            case '1':
                concentrationLimits = waterConcentrationLimits;
                supplyTempLimits = waterTemperatureLimits;
                returnTempLimits = waterTemperatureLimits;
                break;
            case '2':
                concentrationLimits = nonWaterConcentrationLimits;
                supplyTempLimits = ethyleneGlycolSupplyTemperatureLimits;
                returnTempLimits = nonWaterReturnTemperatureLimits;
                break;
            case '3':
                concentrationLimits = nonWaterConcentrationLimits;
                supplyTempLimits = propyleneGlycolSupplyTemperatureLimits;
                returnTempLimits = nonWaterReturnTemperatureLimits;
                break;
            case '4':
                concentrationLimits = ethanolConcentrationLimits;
                supplyTempLimits = ethanolSupplyTemperatureLimits;
                returnTempLimits = nonWaterReturnTemperatureLimits;
                break;
            default:
                console.error('Cannot get limits for unknown fluid type');
        }

        if (value !== '1' && this.fluidConcentrationIsInvalid(this.state.fluidConcentration, concentrationLimits)) {
            let fn = this.getFluidName(value);
            let min = concentrationLimits.min;
            let max = concentrationLimits.max;
            this.setError(
                'fluid_concentration',
                this.props.intl.formatMessage(
                    { id: 'intervalerror', defaultMessage: 'Värdet för {value} får vara mellan {min} och {max}' },
                    { value: fn, min: min, max: max },
                ),
                () =>
                    this.setState({
                        fluidConcentrationLimits: concentrationLimits,
                        supplyTempLimits: supplyTempLimits,
                        returnTempLimits: returnTempLimits,
                        fluidType: value,
                    }),
            );
        } else {
            this.clearError('fluid_concentration', () =>
                this.setState(
                    (prevState) => ({
                        fluidConcentrationLimits: concentrationLimits,
                        supplyTempLimits: supplyTempLimits,
                        returnTempLimits: returnTempLimits,
                        fluidType: value,
                        fluidConcentration: value === '1' ? '100' : prevState.fluidConcentration,
                    }),
                    () => this.tryFetch(),
                ),
            );
        }
    }

    setFluidConcentration(name, value) {
        if (this.fluidConcentrationIsInvalid(value, this.state.fluidConcentrationLimits)) {
            let fn = this.getFluidName(this.state.fluidType);
            let min = this.state.fluidConcentrationLimits.min;
            let max = this.state.fluidConcentrationLimits.max;
            this.setError(
                name,
                this.props.intl.formatMessage(
                    { id: 'intervalerror', defaultMessage: 'Värdet för {value} får vara mellan {min} och {max}' },
                    { value: fn, min: min, max: max },
                ),
                () => this.setState({ fluidConcentration: value }),
            );
        } else {
            this.clearError('fluid_concentration', () =>
                this.setState({ fluidConcentration: value }, () => this.tryFetch()),
            );
        }
    }

    handleCircuitEffectChange(value, name, index) {
        if (!value || parseInt(value) <= 0) {
            const msg = this.props.intl.formatMessage({
                id: 'effectmustbeseterror',
                defaultMessage: 'Effekt måste anges',
            });
            this.setError(name + (index + 1), msg, this.setCircuitEffect(index, value, false));
        } else {
            this.clearError(name + (index + 1), this.setCircuitEffect(index, value, true));
        }
    }

    setCircuitEffect(index, value, shouldTryFetch) {
        this.setState(
            (prevState) => {
                let cs = [...prevState.circuits];
                let c = cs[index];

                c.effect = value;

                return { circuits: cs };
            },
            shouldTryFetch ? () => this.tryFetch() : null,
        );
    }

    setCircuitSupplyTemp(index, value, name) {
        let invalid = false;
        this.setState(
            (prevState) => {
                let cs = [...prevState.circuits];
                let c = cs[index];

                c.supplyTemp = value;

                let topSupplyTemp = getTopSupplyTemp(cs);

                let errors = { ...prevState.errors };
                if (this.setTemperatureErrors(index, prevState.selectedSystemType, c, errors, name)) {
                    invalid = true;

                    return { circuits: cs, topSupplyTemp: topSupplyTemp.toString(), errors: errors };
                }

                if (
                    value < parseInt(this.state.supplyTempLimits.min, 10) ||
                    value > parseInt(this.state.supplyTempLimits.max, 10)
                ) {
                    let ln = this.getFluidName(this.state.fluidType);
                    let min = this.state.supplyTempLimits.min;
                    let max = this.state.supplyTempLimits.max;
                    let msg = this.props.intl.formatMessage(
                        { id: 'intervalerror', defaultMessage: 'Värdet för {value} får vara mellan {min} och {max}' },
                        { value: ln, min: min, max: max },
                    );
                    errors[name + (index + 1)] = msg;
                    //errors['safety_supply-temp'] = msg;

                    invalid = true;
                }

                return { circuits: cs, topSupplyTemp: topSupplyTemp.toString(), errors: errors };
            },
            invalid ? null : () => this.tryFetch(),
        );
    }

    setCircuitReturnTemp(index, value, name) {
        let invalid = false;
        this.setState(
            (prevState) => {
                let cs = [...prevState.circuits];
                let c = cs[index];

                c.returnTemp = value;

                let lowestReturnTemp = getLowestReturnTemp(cs);

                let errors = { ...prevState.errors };
                if (this.setTemperatureErrors(index, prevState.selectedSystemType, c, errors, name)) {
                    invalid = true;

                    return { circuits: cs, lowestReturnTemp: lowestReturnTemp.toString(), errors: errors };
                }

                if (
                    value < parseInt(this.state.returnTempLimits.min, 10) ||
                    value > parseInt(this.state.returnTempLimits.max, 10)
                ) {
                    let ln = this.getFluidName(this.state.fluidType);
                    let min = this.state.returnTempLimits.min;
                    let max = this.state.returnTempLimits.max;
                    let msg = this.props.intl.formatMessage(
                        { id: 'intervalerror', defaultMessage: 'Värdet för {value} får vara mellan {min} och {max}' },
                        { value: ln, min: min, max: max },
                    );
                    errors[name + (index + 1)] = msg;
                    //errors['safety_return-temp'] = msg;

                    invalid = true;
                }

                return { circuits: cs, lowestReturnTemp: lowestReturnTemp.toString(), errors: errors };
            },
            invalid ? null : () => this.tryFetch(),
        );
    }

    setCircuitType(index, value) {
        this.setState(
            (prevState) => {
                let cs = [...prevState.circuits];
                let c = cs[index];

                c.type = value;

                return { circuits: cs };
            },
            () => this.tryFetch(),
        );
    }

    setStaticHeight(value, name) {
        if (value < this.state.staticHeightLimits.min || value > this.state.staticHeightLimits.max) {
            this.setError(
                name,
                this.props.intl.formatMessage(
                    { id: 'intervalerror', defaultMessage: 'Värdet för {value} får vara mellan {min} och {max}' },
                    {
                        value: this.props.intl.formatMessage({ id: 'staticheight', defaultMessage: 'Statisk höjd (Hst)' }),
                        min: this.state.staticHeightLimits.min,
                        max: this.state.staticHeightLimits.max,
                    },
                    ));
                   this.setState({ staticHeight: value, systemOpeningPressure: false, openingPressure: '' });
        } else {
            this.clearError(name, () =>
                this.setState({ staticHeight: value, systemOpeningPressure: false, openingPressure: '' }, () =>
                    this.tryFetch(),
                ),
            );
        }
    }

    getTemperatureRatioErrors(selectedSystemType) {
        let results = [];

        this.state.circuits.forEach((c, i) => {
            let supplyName = 'circuit_supply-temp_' + (i + 1);
            let returnName = 'circuit_return-temp_' + (i + 1);

            if (selectedSystemType === 'cooling') {
                if (parseInt(c.supplyTemp, 10) > parseInt(c.returnTemp, 10)) {
                    const msg = this.props.intl.formatMessage(
                        { id: 'shouldbegreaterthanerror', defaultMessage: '{value1} skall vara högre än {value2}' },
                        {
                            value1: this.props.intl.formatMessage({ id: 'returntemp', defaultMessage: 'Returtemp.' }),
                            value2: this.props.intl.formatMessage({ id: 'supplytemp', defaultMessage: 'Tillopstemp.' }),
                        },
                    );
                    results.push({ name: supplyName, msg: msg });
                    results.push({ name: returnName, msg: msg });
                } else {
                    results.push({ name: supplyName, msg: '' });
                    results.push({ name: returnName, msg: '' });
                }
            } else if (selectedSystemType === 'heating') {
                if (parseInt(c.supplyTemp, 10) < parseInt(c.returnTemp, 10)) {
                    const msg = this.props.intl.formatMessage(
                        { id: 'shouldbegreaterthanerror', defaultMessage: '{value1} skall vara högre än {value2}' },
                        {
                            value2: this.props.intl.formatMessage({ id: 'returntemp', defaultMessage: 'Returtemp.' }),
                            value1: this.props.intl.formatMessage({ id: 'supplytemp', defaultMessage: 'Tillopstemp.' }),
                        },
                    );

                    results.push({ name: supplyName, msg: msg });
                    results.push({ name: returnName, msg: msg });
                } else {
                    results.push({ name: supplyName, msg: '' });
                    results.push({ name: returnName, msg: '' });
                }
            }
        });

        return results;
    }

    setHeatingOrCooling(value) {
        let invalid = false;
        this.setState(
            (prevState) => {
                let errors = { ...prevState.errors };

                let temperatureErrors = this.getTemperatureRatioErrors(value);
                for (var i = 0; i < temperatureErrors.length; i++) {
                    let error = temperatureErrors[i];
                    errors[error.name] = error.msg;
                    if (error.msg) {
                        invalid = true;
                    }
                }

                return { errors: errors, selectedSystemType: value, manualVolumeCalculation: true };
            },
            invalid ? null : () => this.tryFetch(),
        );
    }

    setOpeningPressure(value, name, newPressureState, fetchFn, staticHeight) {
        if (value) {
            let op = parseFloat(value, 10);
            if (
                op < parseFloat(this.state.openingPressureLimits.min, 10) ||
                op > parseFloat(this.state.openingPressureLimits.max, 10)
            ) {
                this.setError(
                    name,
                    this.props.intl.formatMessage({
                        id: 'valuebetweenzeroandtenerror',
                        defaultMessage: 'Värdet får vara mellan 0 och 10',
                    })
                );
                this.setState(newPressureState);
            } else if (staticHeight && parseInt(staticHeight, 10) / 10 + 1 > op) {
                this.setError(
                    name,
                    this.props.intl.formatMessage({
                        id: 'minimumopeningpressure',
                        defaultMessage: 'Öppningstrycket måste vara minst statisk höjd + 1 bar',
                    })
                );
                this.setState(newPressureState);
            } else {
                this.clearError(name);
                this.setState(newPressureState, () => fetchFn());
            }
        } else {
            this.clearError(name);
                this.setState(newPressureState, () => fetchFn());
        }
    }

    setPipeLength(value) {
        let invalid = false;
        this.setState(
            (prevState) => {
                let errors = { ...prevState.errors };
                if (value && !prevState.pipeType) {
                    invalid = true;

                    errors['pipe_type'] = this.props.intl.formatMessage({
                        id: 'mustpickapipe',
                        defaultMessage: 'Måste välja ett rör när längd angetts',
                    });
                    return { pipeLength: value, errors: errors };
                } else {
                    errors['pipe_type'] = '';
                    return { pipeLength: value, errors: errors };
                }
            },
            invalid ? null : () => this.tryFetch(),
        );
    }

    setTemperatureErrors(index, selectedSystemType, c, errors, name) {
        let TempName = name + (index + 1);

        let setTempError = (msg) => {
            errors[TempName] = msg;
        };

        let clearTempError = () => {
            errors[TempName] = '';
        };

        let hasSetErrors = false;

        if (
            (c.supplyTemp && !Number.isInteger(parseFloat(c.supplyTemp))) ||
            (c.returnTemp && !Number.isInteger(parseFloat(c.returnTemp)))
        ) {
            const msg = this.props.intl.formatMessage({
                id: 'temperaturemustbeinteger',
                defaultMessage: 'Temperaturen måste vara ett heltal',
            });
            setTempError(msg);

            hasSetErrors = true;
        } else {
            if (selectedSystemType === 'cooling') {
                if (parseInt(c.supplyTemp, 10) > parseInt(c.returnTemp, 10)) {
                    const msg = this.props.intl.formatMessage(
                        { id: 'shouldbegreaterthanerror', defaultMessage: '{value1} skall vara högre än {value2}' },
                        {
                            value1: this.props.intl.formatMessage({ id: 'returntemp', defaultMessage: 'Returtemp.' }),
                            value2: this.props.intl.formatMessage({ id: 'supplytemp', defaultMessage: 'Tillopstemp.' }),
                        },
                    );
                    setTempError(msg);

                    hasSetErrors = true;
                } else {
                    clearTempError();
                }
            } else if (selectedSystemType === 'heating') {
                if (parseInt(c.supplyTemp, 10) < parseInt(c.returnTemp, 10)) {
                    const msg = this.props.intl.formatMessage(
                        { id: 'shouldbegreaterthanerror', defaultMessage: '{value1} skall vara högre än {value2}' },
                        {
                            value2: this.props.intl.formatMessage({ id: 'returntemp', defaultMessage: 'Returtemp.' }),
                            value1: this.props.intl.formatMessage({ id: 'supplytemp', defaultMessage: 'Tillopstemp.' }),
                        },
                    );
                    setTempError(msg);
                    hasSetErrors = true;
                } else {
                    clearTempError();
                }
            }
        }
        return hasSetErrors;
    }

    onVesselChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        var index;
        if (e.target.name.substring(0, 8) === 'circuit_') {
            name = e.target.name.substring(0, e.target.name.length - 1);
            index = e.target.name[e.target.name.length - 1] - 1;
        }

        switch (name) {
            case 'circuit_effect_': {
                this.handleCircuitEffectChange(value, name, index);
                break;
            }
            case 'circuit_supply-temp_': {
                this.setCircuitSupplyTemp(index, value, name);
                break;
            }
            case 'circuit_return-temp_': {
                this.setCircuitReturnTemp(index, value, name);
                break;
            }
            case 'circuit_type_': {
                this.setCircuitType(index, value);
                break;
            }

            case 'object_name': {
                this.setState({ objectName: value });
                break;
            }
            case 'static_height': {
                this.setStaticHeight(value, name);
                break;
            }
            case 'opening_pressure': {
                this.setOpeningPressure(
                    value,
                    name,
                    { openingPressure: value, systemOpeningPressure: false },
                    this.tryFetch,
                    this.state.staticHeight,
                );
                break;
            }
            case 'heating_or_cooling': {
                this.setHeatingOrCooling(value);
                break;
            }
            case 'fluid_type': {
                this.setFluidTypeAndLimits(value);
                break;
            }
            case 'fluid_concentration': {
                this.setFluidConcentration(name, value);
                break;
            }
            case 'pipe_type': {
                this.clearError(name, () => this.setState({ pipeType: value }, () => this.tryFetch()));
                break;
            }
            case 'pipe_length': {
                this.setPipeLength(value);
                break;
            }
            case 'manual_addend': {
                this.setState({ manualAddend: value }, () => this.tryFetch());
                break;
            }
            case 'total_system_volume': {
                this.setState({ totalSystemVolume: value }, () => this.tryFetch());
                break;
            }
            case 'vessel_type': {
                this.setState({ vesselType: value }, () => this.tryFetch());
                break;
            }
            default:
                console.error('Value of ' + name + ' is not saved');
        }
    }

    fluidConcentrationIsInvalid(value, concentrationLimits) {
        return value < parseInt(concentrationLimits.min, 10) || value > parseInt(concentrationLimits.max, 10);
    }

    setSafetyTemperature(value, name, newState, callback) {
        if (value < parseInt(safetyTemperatureLimits.min, 10) || value > parseInt(safetyTemperatureLimits.max, 10)) {
            this.setError(
                name,
                this.props.intl.formatMessage({
                    id: 'safetyTemperatureError',
                    defaultMessage: 'Kontakta Armatec',
                }),
            );
        } else {
            this.clearError(name);
        }

        this.setState(newState, callback ? () => callback() : null);
    }

    setError(name, message, callback) {
        this.setState(
            (prevState) => {
                let errors = { ...prevState.errors };
                errors[name] = message;

                return {
                    errors: errors,
                };
            },
            callback ? () => callback() : null,
        );
    }

    clearError(name, callback) {
        this.setState(
            (prevState) => {
                let errors = { ...prevState.errors };
                errors[name] = '';

                return {
                    errors: errors,
                };
            },
            callback ? () => callback() : null,
        );
    }

    clear() {
        this.setState({
            ...initialState,
            showVessel: true,
            circuits: [...initialState.circuits.map((x) => ({ ...x }))],
        });

        this.objectNameRef.current.focus();
    }

    onChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        switch (name) {
            case 'results_or_offer': {
                this.setState({ selectedEmailType: value });
                break;
            }
            case 'result_email':
            case 'offer_email': {
                this.setState({ email: value, errorEmail: false });
                break;
            }
            case 'offer_name': {
                this.setState({ offerName: value });
                break;
            }
            case 'offer_company': {
                this.setState({ offerCompany: value });
                break;
            }
            case 'offer_customer_number': {
                this.setState({ offerCustomerNumber: value });
                break;
            }
            case 'offer_location': {
                this.setState({ offerLocation: value });
                break;
            }
            case 'offer_phone_number': {
                this.setState({ offerPhoneNumber: value });
                break;
            }
            case 'offer_information': {
                this.setState({ offerInformation: value });
                break;
            }
            default:
                console.error('Value of ' + name + ' is not saved');
        }
    }

    onSafetyChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        var index;
        if (e.target.name.substring(0, 13) === 'construction_') {
            name = e.target.name.substring(0, e.target.name.length - 1);
            index = e.target.name[e.target.name.length - 1] - 1;
        }

        switch (name) {
            case 'safety_object_name': {
                this.setState({ safetyObjectName: value }, () => this.tryFetchSafety());
                break;
            }
            case 'safety_static_height': {
                this.setState(
                    { safetyStaticHeight: value, systemSafetyOpeningPressure: false, safetyOpeningPressure: '' },
                    () => this.tryFetchSafety(),
                );
                break;
            }
            case 'safety_opening_pressure': {
                this.setOpeningPressure(
                    value,
                    name,
                    { safetyOpeningPressure: value, systemSafetyOpeningPressure: false },
                    this.tryFetchSafety,
                    this.state.safetyStaticHeight,
                );
                break;
            }
            case 'safety_supply-temp': {
                this.setSafetyTemperature(value, name, { safetyTopSupplyTemp: value }, this.tryFetchSafety);
                break;
            }
            case 'safety_return-temp': {
                this.setSafetyTemperature(value, name, { safetyLowestReturnTemp: value }, this.tryFetchSafety);
                break;
            }
            case 'construction_effect_': {
                this.handleConstructionEffectChange(value, name, index);
                break;
            }
            case 'construction_pipe-type_': {
                this.setConstructionPipeType(index, value);
                break;
            }
            case 'construction_boiler-type_': {
                this.setConstructionBoilerType(index, value);
                break;
            }

            case 'constructions_type': {
                this.setState(
                    {
                        constructionType: value,
                        constructions: this.state.circuits
                            ? this.state.circuits.map((x) => ({ effect: x.effect, pipeType: '', boilerType: '1' }))
                            : [{ effect: '', pipeType: '', boilerType: '1' }],
                        errors: this.removeConstructionErrors(),
                        safetyResults: null,
                    },
                    () => this.tryFetchSafety(),
                );
                break;
            }
            case 'safety-valve_direct': {
                this.setState({ safetyValveDirect: value }, () => this.tryFetchSafety());
                break;
            }
            case 'zero_flow_approved': {
                this.setState({ zeroFlowApproved: value }, () => this.tryFetchSafety());
                break;
            }
            case 'type_of_system': {
                this.setState({ typeOfSystem: value }, () => this.tryFetchSafety());
                break;
            }
            case 'heat-pump_amount': {
                this.setState({ heatPumpAmount: value }, () => this.tryFetchSafety());
                break;
            }

            default:
                console.error('Value of ' + name + ' is not saved');
        }
    }

    handleConstructionEffectChange(value, name, index) {
        let msg = '';

        if (!value || parseInt(value) <= 0) {
            msg = 'Effekt måste anges';
            return this.setError(name + (index + 1), msg, this.setConstructionEffect(index, value, false));
        }

        if (
            this.state.constructions.length > 1 &&
            this.state.constructionType === '1' &&
            this.getSumOfConstructionEffect(index, value) > 1500
        ) {
            msg = this.props.intl.formatMessage({
                id: 'boilersumerror',
                defaultMessage: 'För hög totaleffekt',
            });

            return this.setError(name + (index + 1), msg, this.setConstructionEffect(index, value, false));
        }

        this.clearError(name + (index + 1), this.setConstructionEffect(index, value, true));
    }

    getSumOfConstructionEffect(index, value) {
        return this.state.constructions.reduce(
            (sum, x, idx) => sum + (idx === index ? Number.parseFloat(value) : Number.parseFloat(x.effect)),
            0,
        );
    }

    setConstructionEffect(index, value, shouldTryFetch) {
        this.setState(
            (prevState) => {
                let cs = [...prevState.constructions];
                let c = cs[index];

                c.effect = value;

                return { constructions: cs };
            },
            shouldTryFetch ? () => this.tryFetchSafety() : null,
        );
    }

    setConstructionPipeType(index, value) {
        this.setState(
            (prevState) => {
                let cs = [...prevState.constructions];
                let c = cs[index];

                c.pipeType = value;
                return { constructions: cs };
            },
            () => this.tryFetchSafety(),
        );
    }

    getSendToCartUrl() {
        let products = [];

        if (this.state.vesselResults && this.state.vesselResults.alternativeOne) {
            if (this.state.alternativeOneSelected) {
                products.push(
                    ...this.state.vesselResults.alternativeOne.products,
                    ...this.state.vesselResults.alternativeOne.accessories,
                );
            } else {
                products.push(
                    ...this.state.vesselResults.alternativeTwo.products,
                    ...this.state.vesselResults.alternativeTwo.accessories,
                );
            }
        }

        if (this.state.safetyResults) {
            products.push(...this.state.safetyResults.products);
            products.push(...this.state.safetyResults.safetyAccessories);

            for (let index = 0; index < this.state.safetyResults.alternatives.length; index++) {
                const alternativeForMainArticle = this.state.safetyResults.alternatives[index];

                products.push(...alternativeForMainArticle.alternatives.map((t) => ({ ...t, isAlternative: true })));
            }
        }

        let request = products
            .reduce((cartObject, product) => {
                let existingCartItem = cartObject.find((x) => x.articleNumber === product.articleNumber);
                if (existingCartItem) {
                    if (!product.isAlternative) {
                        existingCartItem.count++;
                    }

                    return cartObject;
                }

                cartObject.push({ articleNumber: product.articleNumber, count: product.isAlternative ? 0 : 1 });

                return cartObject;
            }, [])
            .reduce(
                (requestString, cartItem) =>
                    (requestString += `${cartItem.articleNumber.substring(3)}|${cartItem.count};`),
                '',
            );

        return request;
    }

    validateEmail(email) {
        // eslint-disable-next-line no-useless-escape
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    async send() {
        if (!this.validateEmail(this.state.email)) {
            this.setState({ errorEmail: true });
            return;
        }
        let url = '';
        let data = {
            alternative: !this.state.vesselResults
                ? null
                : this.state.alternativeOneSelected
                ? this.state.vesselResults.alternativeOne
                : this.state.vesselResults.alternativeTwo,
            email: this.state.email,
            values: this.state.records,
            vesselInputs: {
                objectName: this.state.objectName,
                staticHeight: this.state.staticHeight,
                openingPressure: this.state.openingPressure,
                topSupplyTemp: this.state.topSupplyTemp,
                lowestReturnTemp: this.state.lowestReturnTemp,
                selectedSystemType: this.state.selectedSystemType === 'heating' ? 'Värmesystem' : 'Kylsystem',
                fluidType: this.getFluidName(this.state.fluidType),
                fluidConcentration: this.state.fluidConcentration,
                circuits: this.state.circuits,
                totalSystemVolume: this.state.totalSystemVolume,
                vesselTypeName: this.getVesselTypeName(),
                vesselType: Number.parseInt(this.state.vesselType),
                manualVolumeCalculation: this.state.manualVolumeCalculation
            },
            safetyInputs:
                this.state.safetyResults && this.state.safetyResults.products
                    ? {
                          safetyObjectName: this.state.safetyObjectName,
                          safetyStaticHeight: this.state.safetyStaticHeight,
                          safetyOpeningPressure: this.state.safetyOpeningPressure,
                          safetyTopSupplyTemp: this.state.safetyTopSupplyTemp,
                          safetyLowestReturnTemp: this.state.safetyLowestReturnTemp,
                          constructionTypeName: this.getConstructionTypeName(),
                          constructionType: this.state.constructionType,
                          constructions: [
                              ...this.state.constructions.map((x) => {
                                  const construction = { ...x };
                                  construction.boilerType = this.getBoilerTypeName(construction.boilerType);
                                  return construction;
                              }),
                          ],
                          zeroFlowApproved: this.props.intl.formatMessage({
                              id: this.state.zeroFlowApproved,
                              defaultMessage: 'ja/nej',
                          }),
                          safetyValveDirect: this.props.intl.formatMessage({
                              id: this.state.safetyValveDirect,
                              defaultMessage: 'ja/nej',
                          }),
                          typeOfSystem: this.props.intl.formatMessage({
                              id: this.state.typeOfSystem,
                              defaultMessage: 'open/closed',
                          }),
                          heatPumpAmount: this.state.heatPumpAmount,
                      }
                    : null,
            safetyAccessories:
                this.state.safetyResults && this.state.safetyResults.products
                    ? {
                          products: this.state.safetyResults.products || [],
                          alternatives: this.state.safetyResults.alternatives || [],
                          accessories: this.state.safetyResults.safetyAccessories || [],
                      }
                    : null,
        };
        if (this.state.selectedEmailType === 'results') {
            url = `${this.state.rootUrl}varmber`;
        } else {
            url = `${this.state.rootUrl}varmber/offer`;
            data = {
                ...data,
                name: this.state.offerName,
                company: this.state.offerCompany,
                customerNumber: this.state.offerCustomerNumber,
                city: this.state.offerLocation,
                phoneNumber: this.state.offerPhoneNumber,
                information: this.state.offerInformation,
            };
        }

        await this.postData(url, data);
    }

    async postData(url = '', data = {}) {
        // Default options are marked with *
        this.setState({ emailIsSending: true });
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                responeType: 'blob',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });

        if (response.status === 200) {
            this.setState({ emailSent: true, emailSentError: false, emailIsSending: false });

            const jsonresp = await response.json();
            for (let index = 0; index < jsonresp.length; index++) {
                const pdf = jsonresp[index].data;

                if (!pdf) {
                    continue;
                }

                var binary = atob(pdf.replace(/\s/g, ''));
                var len = binary.length;
                var buffer = new ArrayBuffer(len);
                var view = new Uint8Array(buffer);
                for (var i = 0; i < len; i++) {
                    view[i] = binary.charCodeAt(i);
                }

                var blob = new Blob([view], { type: 'application/pdf' });

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                // parses JSON response into native JavaScript objects
                const link = document.createElement('a');
                // create a blobURI pointing to our Blob
                link.href = URL.createObjectURL(blob);
                link.download = jsonresp[index].name;
                // some browser needs the anchor to be in the doc
                document.body.appendChild(link);
                link.click();
                link.remove();
                // in case the Blob uses a lot of memory
                window.addEventListener('focus', (e) => URL.revokeObjectURL(link.href), { once: true });
            }
            setTimeout(() => { this.setState({ emailSent: false })}, 2500);
        } else {
            this.setState({ emailSent: false, emailSentError: true, emailIsSending: false });
        }
    }

    setConstructionBoilerType(index, value) {
        this.setState(
            (prevState) => {
                let cs = [...prevState.constructions];
                let c = cs[index];

                c.boilerType = value;

                return { constructions: cs };
            },
            () => this.tryFetchSafety(),
        );
    }

    addConstruction() {
        if (this.state.constructions.length < 3) {
            this.setState((prevState) => ({
                constructions: [...prevState.constructions, { effect: '', pipeType: '', boilerType: '1' }],
            }));
        }
    }

    deleteConstruction(index) {
        if (index > 0) {
            this.setState(
                (prevState) => {
                    let c = [...prevState.constructions];
                    c.splice(index, 1);

                    let errors = { ...this.state.errors };
                    errors['construction_effect_' + index] = undefined;
                    errors['construction_pipe-type_' + index] = undefined;
                    errors['construction_boiler-type_' + index] = undefined;

                    return { constructions: c, errors: errors };
                },
                () => this.tryFetchSafety(),
            );
        }
    }

    removeConstructionErrors() {
        let errors = { ...this.state.errors };

        errors['construction_effect_1'] = undefined;
        errors['construction_effect_2'] = undefined;
        errors['construction_effect_3'] = undefined;
        errors['construction_pipe-type_1'] = undefined;
        errors['construction_pipe-type_2'] = undefined;
        errors['construction_pipe-type_3'] = undefined;
        errors['construction_boiler-type_1'] = undefined;
        errors['construction_boiler-type_2'] = undefined;
        errors['construction_boiler-type_3'] = undefined;
        return errors;
    }

    componentDidMount() {
        window.addEventListener('wheel', this.blurOnMouseWheel, false);
    }
    
    componentWillUnmount() {
        window.removeEventListener('wheel', this.blurOnMouseWheel, false);
    }

    blurOnMouseWheel() {
        if (document.activeElement.type === "number"){
            document.activeElement.blur();
        }
    }

    render() {
        return (
            <>
                <div className={this.state.working ? 'spinner' : ''} />
                <div style={this.state.working ? blurStyle : null}>
                    <div className="expansion-list-header">
                        <h2>
                            <FormattedMessage id="setsystemtasks" defaultMessage="FYLL I SYSTEMUPPGIFTER" />
                        </h2>
                    </div>

                    <div className="expansion-list">
                        <VesselCategory
                            working={this.state.working}
                            toggleExpansion={() =>
                                this.setState((prevState) => ({
                                    showVessel: !prevState.showVessel,
                                }))
                            }
                            expanded={this.state.showVessel}
                            addCircuit={this.addCircuit}
                            deleteCircuit={this.deleteCircuit}
                            manualVolumeCalculation={this.state.manualVolumeCalculation}
                            setManualVolumeCalculation={this.setManualVolumeCalculation}
                            culvertExpanded={this.state.culvertExpanded}
                            toggleCulvertExpansion={this.toggleCulvertExpansion}
                            manualAddendExpanded={this.state.manualAddendExpanded}
                            toggleManualAddendExpanded={this.toggleManualAddendExpanded}
                            objectName={this.state.objectName}
                            staticHeight={this.state.staticHeight}
                            openingPressure={this.state.openingPressure}
                            selectedSystemType={this.state.selectedSystemType}
                            fluidType={this.state.fluidType}
                            fluidConcentration={this.state.fluidConcentration}
                            circuits={this.state.circuits}
                            pipeType={this.state.pipeType}
                            pipeLength={this.state.pipeLength}
                            manualAddend={this.state.manualAddend}
                            totalSystemVolume={this.state.totalSystemVolume}
                            vesselType={this.state.vesselType}
                            errors={this.state.errors}
                            onChange={this.onVesselChange}
                            records={this.state.records}
                            results={this.state.vesselResults}
                            alternativeOneSelected={this.state.alternativeOneSelected}
                            alternativeTwoSelected={this.state.alternativeTwoSelected}
                            selectAlternativeOne={this.selectAlternativeOne}
                            selectAlternativeTwo={this.selectAlternativeTwo}
                            clear={this.clear}
                            objectNameRef={this.objectNameRef}
                        />
                        <SafetyCategory
                            expanded={this.state.showSafety}
                            addConstruction={this.addConstruction}
                            deleteConstruction={this.deleteConstruction}
                            toggleExpansion={() => {
                                if (!this.state.showSafety) {
                                    this.tryFetchSafety();
                                }
                                this.setState((prevState) => ({
                                    showSafety: !prevState.showSafety,
                                }));
                            }}
                            onChange={this.onSafetyChange}
                            errors={this.state.errors}
                            objectName={this.state.safetyObjectName}
                            results={this.state.safetyResults}
                            staticHeight={this.state.safetyStaticHeight}
                            openingPressure={this.state.safetyOpeningPressure}
                            topSupplyTemp={this.state.safetyTopSupplyTemp}
                            lowestReturnTemp={this.state.safetyLowestReturnTemp}
                            constructionType={this.state.constructionType}
                            constructions={this.state.constructions}
                            safetyValveDirect={this.state.safetyValveDirect}
                            zeroFlowApproved={this.state.zeroFlowApproved}
                            typeOfSystem={this.state.typeOfSystem}
                            heatPumpAmount={this.state.heatPumpAmount}
                        />
                        {(this.state.vesselResults ||
                            this.state.safetyResults) && (
                            <Report
                                noResult={!this.state.alternativeOne || !this.state.alternativeOne.products}
                                send={this.send}
                                selectedEmailType={this.state.selectedEmailType}
                                email={this.state.email}
                                offerName={this.state.offerName}
                                offerCompany={this.state.offerCompany}
                                offerCustomerNumber={this.state.offerCustomerNumber}
                                offerLocation={this.state.offerLocation}
                                offerPhoneNumber={this.state.offerPhoneNumber}
                                offerInformation={this.state.offerInformation}
                                onChange={this.onChange}
                                errorEmail={this.state.errorEmail}
                                emailSent={this.state.emailSent}
                                emailSentError={this.state.emailSentError}
                                emailIsSending={this.state.emailIsSending}
                                getSendToCartUrl={this.getSendToCartUrl}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default injectIntl(Umbrella);
