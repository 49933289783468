import React from 'react';
import { FormattedMessage } from 'react-intl';

const ZeroFlow = props => {
  return (
    <>
      <label className='label'>
        <FormattedMessage id="approvedzeroflowboiler" defaultMessage="Är pannorna godkända som nollflödespannor?"/>
      </label>
      <div className='input_flex_row '>
        <label htmlFor='zfyid' className='container radio_button'>
        <FormattedMessage id="yes" defaultMessage="Ja"/>
          <input
            type='radio'
            name='zero_flow_approved'
            id='zfyid'
            value='yes'
            checked={props.zeroFlowApproved === 'yes'}
            onChange={props.onChange}
          />
          <span className='checkmark'></span>
        </label>
        <label htmlFor='zfnid' className='container radio_button'>
        <FormattedMessage id="no" defaultMessage="No"/>
          <input
            type='radio'
            name='zero_flow_approved'
            id='zfnid'
            value='no'
            checked={props.zeroFlowApproved === 'no'}
            onChange={props.onChange}
          />
          <span className='checkmark'></span>
        </label>
      </div>
    </>
  );
}

export default ZeroFlow;