const vesselFuntions = {
  hasCompleteCircuitsForVessels() {
    let valueIsMissing = false;
    this.state.circuits.forEach(c => {
      if (!c.effect || !c.supplyTemp || !c.returnTemp) {
        valueIsMissing = true;
      }
    });

    return !valueIsMissing;
  },

  tryFetch() {
    let fetched = false;
    if (!this.state.manualVolumeCalculation && this.noErrorsForVolume()) {
      if (!(this.state.pipeLength && !this.state.pipeType) && this.hasCompleteCircuitsForVolume()) {
        if (this.state.totalSystemVolume) {
          if (this.volumeRequestValuesHaveChanged()) {
            fetched = true;
            this.requestVolume();
          }
        } else {
          if (!this.volumeRequestHasBeenSent()) {
            fetched = true;
            this.requestVolume();
          }
        }
      }
    }

    if (!fetched) {
      if (this.noErrorsForVessels()) {
        if (this.state.staticHeight && this.state.totalSystemVolume && this.hasCompleteCircuitsForVessels()) {

          this.requestVesselResult();
        }
      }
    }
  },

  getVesselResult() {
    let url = new URL(this.state.rootUrl + 'varmber/vessels');
    let params = {
      staticHeight: this.state.staticHeight,
      totalSystemVolume: this.state.totalSystemVolume,
      fluidType: this.state.fluidType,
      fluidConcentration: this.state.fluidConcentration,
      circuits: JSON.stringify(this.state.circuits.map((c) => ({
        effectValue: c.effect,
        supplyTemp: c.supplyTemp,
        returnTemp: c.returnTemp
      }))),
      vesselType: this.state.vesselType,
      openingPressure: this.state.systemOpeningPressure ? "" : (this.state.openingPressure ? this.state.openingPressure : "")
    }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    fetch(url).then(response => {
      if (response.status[0] === '4' || response.status[0] === '5') {
        throw Error();
      }

      return response.json();
    }).then(response => {
      this.setState(
        {
          records: response.records, vesselResults: response.results, openingPressure: response.records.openingPressure, systemOpeningPressure: response.records.openingPressure.toString() !== params.openingPressure.toString(), alternativeOneSelected: true,
          safetyObjectName: this.state.objectName,
          safetyOpeningPressure: this.state.openingPressure,
          safetyStaticHeight: this.state.staticHeight,
          safetyTopSupplyTemp: this.state.topSupplyTemp,
          safetyLowestReturnTemp: this.state.lowestReturnTemp,
          emailSent: false,
          emailSentError: false
        },
        () => {
          this.clearError(
            'fetch_products',
            () => this.selectAlternativeOne()
          );
          this.tryFetchSafety();
        }
      );
    }).catch(() => {
      this.setState(
        { vesselResults: null, records: null },
        () => this.setError('fetch_products', this.props.intl.formatMessage({ id: 'fetchproducterror', defaultMessage: "Kunde inte hämta produktförslag, vänligen försök igen" }))
      )

        ;
    }).finally(() => {
      this.setState({ working: false })
    })
  },

  requestVesselResult() {
    this.setState(prevState => {
      let circuits = [...prevState.circuits];
      circuits.forEach(c => {
        c.sentEffect_result = c.effect;
        c.sentSupplyTemp_result = c.supplyTemp;
        c.sentReturnTemp_result = c.returnTemp;
      })

      return {
        working: true,
        circuits: circuits,
        sentStaticHeight_vessels: prevState.staticHeight,
        sentOpeningPressure_vessels: prevState.openingPressure,
        sentFluidType: prevState.fluidType,
        sentFluidConcentration: prevState.fluidConcentration,
        sentTotalSystemVolume: prevState.totalSystemVolume,
        sentVesselType: prevState.vesselType,
        sentNumberOfCircuits_results: prevState.circuits.length
      }
    }, this.getVesselResult())
  },

  vesselRequestValuesHaveChanged() {
    let changed = false;
    if (this.state.sentNumberOfCircuits_results && this.state.circuits.length !== this.state.sentNumberOfCircuits_results) {
      changed = true;
    } else if (this.state.sentStaticHeight_vessels && this.state.staticHeight !== this.state.sentStaticHeight_vessels) {
      changed = true;
    } else if (
      (this.state.sentOpeningPressure_vessels && this.state.openingPressure !== this.state.sentOpeningPressure_vessels) ||
      (!this.state.sentOpeningPressure_vessels && this.state.openingPressure)
    ) {
      changed = true;
    } else if (
      this.state.sentFluidType && this.state.fluidType !== this.state.sentFluidType
    ) {
      changed = true;
    } else if (
      this.state.sentFluidConcentration && this.state.fluidConcentration !== this.state.sentFluidConcentration
    ) {
      changed = true;
    } else if (
      this.state.sentTotalSystemVolume && this.state.totalSystemVolume !== this.state.sentTotalSystemVolume
    ) {
      changed = true;
    } else if (
      this.state.sentVesselType && this.state.vesselType !== this.state.sentVesselType
    ) {
      changed = true;
    } else {
      this.state.circuits.forEach(c => {
        if ((c.sentEffect_result && c.effect !== c.sentEffect_result) || (!c.sentEffect_result && c.effect)) {
          changed = true;
        } else if (
          (c.sentSupplyTemp_result && c.supplyTemp !== c.sentSupplyTemp_result) ||
          (!c.sentSupplyTemp_result && c.supplyTemp)
        ) {
          changed = true;
        } else if (
          (c.sentReturnTemp_result && c.returnTemp !== c.sentReturnTemp_result) ||
          (!c.sentReturnTemp_result && c.returnTemp)
        ) {
          changed = true;
        }
      });
    }

    return changed;
  },

  vesselRequestHasBeenSent() {
    let sent = false;
    if (this.state.sentNumberOfCircuits_results) {
      sent = true;
    } else if (this.state.sentStaticHeight_vessels) {
      sent = true;
    } else if (this.state.sentOpeningPressure_vessels) {
      sent = true;
    } else if (this.state.sentFluidType) {
      sent = true;
    } else if (this.state.sentFluidConcentration) {
      sent = true;
    } else {
      this.state.circuits.forEach(c => {
        if (c.sentEffect_result) {
          sent = true;
        } else if (c.sentSupplyTemp_result) {
          sent = true;
        } else if (c.sentReturnTemp_result) {
          sent = true;
        }
      });
    }
    return sent;
  },

  noErrorsForVessels() {
    let hasErrors =
      this.state.errors['circuit_effect_1'] ||
      this.state.errors['circuit_effect_2'] ||
      this.state.errors['circuit_effect_3'] ||
      this.state.errors['circuit_supply-temp_1'] ||
      this.state.errors['circuit_supply-temp_2'] ||
      this.state.errors['circuit_supply-temp_3'] ||
      this.state.errors['circuit_return-temp_1'] ||
      this.state.errors['circuit_return-temp_2'] ||
      this.state.errors['circuit_return-temp_3'] ||
      this.state.errors['static_height'] ||
      this.state.errors['opening_pressure'] ||
      this.state.errors['fluid_type'] ||
      this.state.errors['fluid_concentration'];

    return !hasErrors;
  }
};

export default vesselFuntions;