import React from 'react';
import infoIcon from '../../icons/info.svg';
import FormError from './formError';
import { FormattedMessage } from 'react-intl';

const NumberInputWithInfo = (props) => {
    const onBlur = (e) => {
        let newValue = '';
        if (e.target.value) {
            if (e.target.validity.badInput) {
                newValue = Math.round(props.value);
            } else if (props.step) {
                newValue = Math.round(e.target.value / props.step) * props.step;
            } else {
                newValue = Math.round(e.target.value);
            }
        }
        // eslint-disable-next-line
        newValue != props.value &&
            props.onChange({
                ...e,
                target: { name: props.name, value: newValue },
            });
    };
    return (
        <>
            <label htmlFor={props.id} className="label">
                {props.label}
                {props.infoText && (
                    <span className="info-icon">
                        <span>{props.infoText}</span>
                        <img src={infoIcon} alt={props.alt} className={'input-icon-info'} />
                    </span>
                )}

                {props.optional && (
                    <i>
                        {' '}
                        (<FormattedMessage id="optional" defaultMessage="Valfritt" />)
                    </i>
                )}
            </label>
            <div className="number-input-outer-container">
                <div className={`${props.suffix} number-input-container ${props.readOnly ? 'read-only' : null}`}>
                    <input
                        type="number"
                        placeholder={props.placeholder}
                        name={props.name}
                        id={props.id}
                        value={props.value}
                        className={props.errors && props.errors[props.name] ? 'invalid-input' : null}
                        readOnly={props.readOnly}
                        disabled={props.readOnly}
                        onChange={props.onChange}
                        onBlur={onBlur}
                        aria-describedby={props.errors && props.errors[props.name] ? props.name + '_error' : ''}
                        step={props.step}
                    />
                </div>
                {props.errors && <FormError errors={props.errors} name={props.name} />}
            </div>
        </>
    );
};

export default NumberInputWithInfo;
