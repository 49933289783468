import React from 'react';
import addIcon from '../../icons/add-black.svg';
import subtractIcon from '../../icons/subtract.svg';
import SafetyCategoryForm from './safetyCategoryForm';

const SafetyCategory = props => {
  return (
    <>
      <button
        className={props.expanded ? 'expansion-bar active' : 'expansion-bar'}
        onClick={props.toggleExpansion}>
        {!props.expanded ? (
          <img
            src={addIcon}
            alt='Expandera säkerhetsutrustningskategorin'
            className='iconStyle' />
        ) : (
            <img
              src={subtractIcon}
              alt='Kollapsa säkerhetsutrustningskategorin'
              className='iconStyle' />
          )}

        <h3>SÄKERHETSUTRUSTNING</h3>
      </button>
      {props.expanded &&
        <SafetyCategoryForm
          addConstruction={props.addConstruction}
          deleteConstruction={props.deleteConstruction}
          results={props.results}
          visible={props.expanded}
          onChange={props.onChange}
          errors={props.errors}
          objectName={props.objectName}
          staticHeight={props.staticHeight}
          openingPressure={props.openingPressure}
          topSupplyTemp={props.topSupplyTemp}
          lowestReturnTemp={props.lowestReturnTemp}
          constructionType={props.constructionType}
          constructions={props.constructions}
          safetyValveDirect={props.safetyValveDirect}
          zeroFlowApproved={props.zeroFlowApproved}
          typeOfSystem={props.typeOfSystem}
          heatPumpAmount={props.heatPumpAmount} />
      }
    </>
  );
};

export default SafetyCategory;