import React from 'react';
import FormError from './formError';

const invalidStyle = {
    borderColor: 'red',
    color: 'red',
};

const NumberInput = (props) => {
    const onBlur = (e) => {
        let newValue = '';
        if (e.target.value) {
            if (e.target.validity.badInput) {
                newValue = Math.round(props.value);
            } else if (props.step) {
                newValue = Math.round(e.target.value / props.step) * props.step;
            } else {
                newValue = Math.round(e.target.value);
            }
        }
        // eslint-disable-next-line
        newValue != props.value &&
            props.onChange({
                ...e,
                target: { name: props.name, value: newValue },
            });
    };

    return (
        <>
            <label htmlFor={props.id}>{props.label}</label>
            <div className="number-input-container">
                <div className={`${props.suffix} ${props.readOnly ? 'read-only' : null}`}>
                    <input
                        type="number"
                        placeholder={props.placeholder}
                        name={props.name}
                        id={props.id}
                        readOnly={props.readOnly}
                        disabled={props.readOnly}
                        value={props.value}
                        style={props.errors && props.errors[props.name] ? invalidStyle : null}
                        onChange={props.onChange}
                        onBlur={onBlur}
                        step={props.step}
                        aria-describedby={props.errors && props.errors[props.name] ? props.name + '_error' : ''}
                    />
                </div>
                {props.errors && <FormError errors={props.errors} name={props.name} />}
            </div>
        </>
    );
};

export default NumberInput;
