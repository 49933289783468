import React from 'react';
import NumberInputWithInfo from '../shared/numberInputWithInfo';
import SelectWithInfo from '../shared/selectWithInfo';
import TextInput from '../shared/textInput';
import Circuits from './formItems/circuits';
import Fluid from './formItems/fluid';
import HeatingOrCooling from './formItems/heatingOrCooling';
import Records from './formItems/records';
import Results from './formItems/results';
import Volume from './formItems/volume';
import { useIntl, FormattedMessage } from 'react-intl';

const errorStyle = {
    color: '#D8000C',
    backgroundColor: '#FFD2D2',
};

const VesselCategoryForm = props => {
    const intl = useIntl();
    return (
        <>
            {props.visible && (
                <div className={props.visible ? 'expansion-vessel expanded' : 'expansion-vessel'}>
                    <fieldset className="input_flex">
                        <TextInput
                            label={<FormattedMessage id="nameofobject" defaultMessage="Namn på kalkyl/objekt" />}
                            id="vonid"
                            name="object_name"
                            placeholder="Objekt 1"
                            value={props.objectName}
                            onChange={props.onChange}
                            ref={props.objectNameRef}
                        />
                    </fieldset>

                    <fieldset>
                        <NumberInputWithInfo
                            id="shid"
                            label={<FormattedMessage id="staticheight" defaultMessage="Statisk höjd (Hst)" />}
                            alt="Höjd infoikon"
                            infoText={
                                <FormattedMessage
                                    id="staticheightinfo"
                                    defaultMessage="Avståndet från expansionskärlet upp till anläggningens högsta punkt. Vid ex borrhål är det endast höjd ovan expansionskärl som tas med."
                                />
                            }
                            name="static_height"
                            value={props.staticHeight}
                            onChange={props.onChange}
                            errors={props.errors}
                            suffix="static_height"
                        />
                    </fieldset>
                    <fieldset>
                        <NumberInputWithInfo
                            id="opid"
                            label={<FormattedMessage id="openingpressure" defaultMessage="Öppningstryck (Psv)" />}
                            alt="Valfritt öppningstryck infoikon"
                            infoText={
                                <FormattedMessage
                                    id="openingpressureinfo"
                                    defaultMessage="Om du inte anger något värde kommer Varmber föreslå ett öppningstryck."
                                />
                            }
                            placeholder={intl.formatMessage({ id: 'pickpressure', defaultMessage: 'Välj tryck' })}
                            name="opening_pressure"
                            optional={true}
                            value={props.openingPressure}
                            onChange={props.onChange}
                            errors={props.errors}
                            suffix="pressure"
                            step={0.5}
                        />
                    </fieldset>
                    <fieldset className="radio_button">
                        <HeatingOrCooling selectedSystemType={props.selectedSystemType} onChange={props.onChange} />
                    </fieldset>
                    <fieldset>
                        <Fluid
                            fluidType={props.fluidType}
                            fluidConcentration={props.fluidConcentration}
                            onChange={props.onChange}
                            errors={props.errors}
                        />
                    </fieldset>
                    <div className="add_circuit_wrapper">
                        <Circuits
                            circuits={props.circuits}
                            addCircuit={props.addCircuit}
                            deleteCircuit={props.deleteCircuit}
                            onChange={props.onChange}
                            errors={props.errors}
                            selectedSystemType={props.selectedSystemType}
                        />
                    </div>

                    <Volume
                        circuits={props.circuits}
                        manual={props.manualVolumeCalculation}
                        setManual={props.setManualVolumeCalculation}
                        culvertExpanded={props.culvertExpanded}
                        toggleCulvertExpansion={props.toggleCulvertExpansion}
                        manualAddendExpanded={props.manualAddendExpanded}
                        toggleManualAddendExpanded={props.toggleManualAddendExpanded}
                        pipeType={props.pipeType}
                        pipeLength={props.pipeLength}
                        manualAddend={props.manualAddend}
                        totalSystemVolume={props.totalSystemVolume}
                        onChange={props.onChange}
                        errors={props.errors}
                        selectedSystemType={props.selectedSystemType}
                    />

                    <fieldset className="input_flex">
                        <SelectWithInfo
                            id="vtid"
                            label={intl.formatMessage({ id: 'vesseltype', defaultMessage: 'Typ av kärl' })}
                            alt="Kärltyp infoikon"
                            infoText={intl.formatMessage({
                                id: 'vesseltypeinfo',
                                defaultMessage: 'Vilken typ av expansionskärl som du önskar få förslag på.',
                            })}
                            name="vessel_type"
                            options={[
                                intl.formatMessage({
                                    id: 'closedvesselprepressure',
                                    defaultMessage: 'Slutet kärl med förtryck',
                                }),
                                intl.formatMessage({
                                    id: 'closedcompressvessel',
                                    defaultMessage: 'Slutet kompressorkärl',
                                }),
                                intl.formatMessage({ id: 'openvesselwithpump', defaultMessage: 'Öppet kärl med pump' }),
                            ]}
                            value={props.vesselType}
                            onChange={props.onChange}
                        />
                    </fieldset>

                    {props.errors['fetch_products'] && (
                        <div style={errorStyle}>
                            <strong>{props.errors['fetch_products']}</strong>
                        </div>
                    )}

                    <Records values={props.records} vesselType={props.vesselType} />
                    <Results
                        values={props.results}
                        alternativeOneSelected={props.alternativeOneSelected}
                        alternativeTwoSelected={props.alternativeTwoSelected}
                        selectAlternativeOne={props.selectAlternativeOne}
                        selectAlternativeTwo={props.selectAlternativeTwo}
                        again={props.clear}
                    />
                </div>
            )}
        </>
    );
};

export default VesselCategoryForm;
