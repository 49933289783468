import React from 'react';
import logo from '../images/Varmber_logga_ny.png';
import './../styles/global.scss';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const Introduction = () => {
    return (
        <>
            <div className="section-intro">
                <img className="logotype" src={logo} alt="Varmber logotype" />
                <div>
                    <section className="service_text">
                        {/* <p>
              <FormattedMessage id="greeting" defaultMessage="Välkommen till Armatecs onlineverktyg för dimensionering av expansions och tryckhållningssystem för värme och kylanläggningar samt säkerhetsutrustning för pannor och värmeväxlar och värmepumpar" />
            </p> */}
                        <p>
                            <FormattedHTMLMessage id="readmore" />
                        </p>
                    </section>
                    <div>
                        <section className="r_row">
                            <div>
                                <h4>
                                    <FormattedMessage id="ssen12828" defaultMessage="SS-EN 1282" />
                                </h4>
                                <p>
                                    <FormattedMessage
                                        id="ssen12828description"
                                        defaultMessage="Värmesystem i byggnader – Utförande och installation av
                  vattenburna värmesystem."
                                    />
                                </p>
                            </div>

                            <div>
                                <h4>
                                    <FormattedMessage id="vet2008" defaultMessage="VET 2008" />
                                </h4>
                                <p>
                                    <FormattedMessage
                                        id="vet2008description"
                                        defaultMessage="Vägledning för expansions-, tryckhållnings-, matarvatten- och pumpcirkulationssystem vid pannanläggningar."
                                    />
                                </p>
                            </div>
                            <div>
                                <h4>
                                    <FormattedMessage id="svsu2007" defaultMessage="SVSU 2007" />
                                </h4>
                                <p>
                                    <FormattedMessage
                                        id="svsu2007description"
                                        defaultMessage="Vägledning för säkerhetsutrustning vid pann- och
                  värmeväxlaranläggningar."
                                    />
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Introduction;
