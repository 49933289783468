import React from 'react';
import addIcon from '../../../icons/add-black.svg';
import closeIcon from '../../../icons/close.svg';
import subtractIcon from '../../../icons/subtract.svg';
import NumberInput from '../../shared/numberInput';
import NumberInputWithInfo from '../../shared/numberInputWithInfo';
import Select from '../../shared/select';
import SelectWithInfo from '../../shared/selectWithInfo';
import pipeTypes from '../../shared/pipe';
import { useIntl } from 'react-intl';

const iconStyle = {
    width: '20px',
    height: '20px',
};

const errorStyle = {
    color: '#D8000C',
    backgroundColor: '#FFD2D2',
};

const Volume = props => {
    const intl = useIntl();

    return (
        <>
            {!props.manual && props.selectedSystemType === 'heating' && (
                <div className="section-calculate-system-volume">
                    <fieldset>
                        <header className="box-header">
                            {intl.formatMessage({ id: 'calculatesystemvolume', defaultMessage: 'Beräkna systemvolym' })}
                            <button className="close-button" onClick={() => props.setManual(true)}>
                                <img src={closeIcon} alt="Ikon för att stänga volymhjälpen"></img>
                            </button>
                        </header>
                        {props.errors['fetch_volume'] && (
                            <div style={errorStyle}>
                                <strong>{props.errors['fetch_volume']}</strong>
                            </div>
                        )}
                        <div className="body">
                            <Circuits circuits={props.circuits} onChange={props.onChange} />
                            <Culvert
                                expanded={props.culvertExpanded}
                                toggleExpansion={props.toggleCulvertExpansion}
                                pipeType={props.pipeType}
                                pipeLength={props.pipeLength}
                                onChange={props.onChange}
                                errors={props.errors}
                            />
                            <ManualAddend
                                expanded={props.manualAddendExpanded}
                                toggleExpansion={props.toggleManualAddendExpanded}
                                value={props.manualAddend}
                                onChange={props.onChange}
                            />
                        </div>
                    </fieldset>
                </div>
            )}

            <div className="calculate-volume">
                <div className="input-total-volume">
                    <fieldset>
                        <NumberInputWithInfo
                            styleClass="label"
                            id="tsvid"
                            label={intl.formatMessage({ id: 'totalsystemvolume', defaultMessage: 'Total systemvolym' })}
                            alt="Total systemvolym infoikon"
                            infoText={intl.formatMessage({
                                id: 'totalsystemvolumeinfo',
                                defaultMessage: 'Anläggningens totala systemvolym.',
                            })}
                            name="total_system_volume"
                            readOnly={!props.manual}
                            value={props.totalSystemVolume}
                            onChange={props.onChange}
                            suffix="total_system_volume"
                        />
                    </fieldset>
                </div>
                {props.manual && props.selectedSystemType === 'heating' && (
                    <div className="calculate-button-container">
                        <button
                            type="button"
                            className="btn-primary calculate-button"
                            onClick={() => props.setManual(false)}
                        >
                            {intl
                                .formatMessage({
                                    id: 'calculatesystemvolume',
                                    defaultMessage: 'HJÄLP BERÄKNA SYSTEMVOLYM',
                                })
                                .toUpperCase()}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

const Circuits = props => {
    return (
        <>
            {props.circuits.map((c, i) => (
                <Circuit
                    key={'vol_circ_' + i}
                    index={i + 1}
                    type={c.type}
                    onChange={props.onChange}
                    volume={c.volume}
                />
            ))}
        </>
    );
};

const Circuit = props => {
    const intl = useIntl();

    return (
        <div>
            <Select
                id={'vol_cir_typ_' + props.index}
                label={intl.formatMessage({ id: 'circuit', defaultMessage: 'Krets' }) + ' ' + props.index}
                name={'circuit_type_' + props.index}
                placeholder={props.type ? null : intl.formatMessage({ id: 'choosetype', defaultMessage: 'Välj typ' })}
                value={props.type}
                options={[
                    intl.formatMessage({ id: 'radiators', defaultMessage: 'Radiatorer' }),
                    intl.formatMessage({ id: 'ventilation', defaultMessage: 'Ventilation' }),
                    intl.formatMessage({ id: 'underfloor', defaultMessage: 'Golvvärme' }),
                ]}
                onChange={props.onChange}
            />
            <NumberInput
                id={'vol_cir_vol_' + props.index}
                label={intl.formatMessage({ id: 'calculatedvolume', defaultMessage: 'Beräknad volym' })}
                name={'circuit_volume_' + props.index}
                suffix="volume"
                readOnly
                value={props.volume}
            />
        </div>
    );
};

const Culvert = props => {
    const intl = useIntl();

    return (
        <>
            <button className="expansion-bar" onClick={props.toggleExpansion}>
                {props.expanded && <img src={subtractIcon} alt="Kollapsa kulvert-sektion" style={iconStyle} />}
                {!props.expanded && <img src={addIcon} alt="Expandera kulvert-sektion" style={iconStyle} />}
                <h5>{intl.formatMessage({ id: 'culvert', defaultMessage: 'Kulvert' })}</h5>
            </button>
            {props.expanded && (
                <>
                    <SelectWithInfo
                        id="ptid"
                        label={intl.formatMessage({ id: 'doublepipe', defaultMessage: 'Dubbelrör' })}
                        alt="Välj rör infoikon"
                        infoText={intl.formatMessage({
                            id: 'doublepipeinfo',
                            defaultMessage: 'Välj vilken dimension det är på rören.',
                        })}
                        name="pipe_type"
                        placeholder={
                            props.pipeType ? null : intl.formatMessage({ id: 'choosepipe', defaultMessage: 'Välj rör' })
                        }
                        value={props.pipeType}
                        options={pipeTypes}
                        onChange={props.onChange}
                        errors={props.errors}
                    />
                    <NumberInput
                        id="plid"
                        name="pipe_length"
                        label={intl.formatMessage({ id: 'pipelength', defaultMessage: 'Rörlängd' })}
                        value={props.pipeLength}
                        onChange={props.onChange}
                        suffix="pipe_length"
                    />
                </>
            )}
        </>
    );
};

const ManualAddend = props => {
    const intl = useIntl();

    return (
        <>
            <button className="expansion-bar " onClick={props.toggleExpansion}>
                {props.expanded && <img src={subtractIcon} alt="Kollapsa sektion för eget tillägg" style={iconStyle} />}
                {!props.expanded && <img src={addIcon} alt="Expandera sektion för eget tillägg" style={iconStyle} />}
                <h5>{intl.formatMessage({ id: 'ownsupplement', defaultMessage: 'Eget tillägg' })}</h5>
            </button>
            {props.expanded && (
                <NumberInput
                    id="maid"
                    label={intl.formatMessage({ id: 'volume', defaultMessage: 'Volym' })}
                    name="manual_addend"
                    value={props.value}
                    onChange={props.onChange}
                    className="volume"
                />
            )}
        </>
    );
};

export default Volume;
