export const setConfig = async () =>
    fetch('/appconfig.json')
        .then(response => response.json())
        .then(cfg => {
            config = {
                ...config,
                ...cfg.appConfig,
            }
            return config;
        })
        .catch(() => {
            return config;
        })

export let config = {
    HIDE_BOILERS: "false",
    CART_API_URL: ""
};
