import React from 'react';
import { FormattedMessage } from 'react-intl';

const HeatingOrCooling = props => {
  return (
    <>
      <label className='label'><FormattedMessage id="systemtype" defaultMessage="Systemtyp" /></label>
      <div className='input_flex_row '>
          <label htmlFor='hsid' className='container radio_button'>
            <span>
              <FormattedMessage id="heating" defaultMessage="Värmesystem" /></span>
            <input
              type='radio'
              name='heating_or_cooling'
              id='hsid'
              value='heating'
              checked={props.selectedSystemType === 'heating'}
              onChange={props.onChange}
            />
            <span className='checkmark'></span>
          </label>

        <label htmlFor='csid' className='container radio_button'>
          <FormattedMessage id="cooling" defaultMessage="Kylsystem" />
          <input
            type='radio'
            name='heating_or_cooling'
            id='csid'
            value='cooling'
            checked={props.selectedSystemType === 'cooling'}
            onChange={props.onChange}
          />
          <span className='checkmark'></span>
        </label>
      </div>
    </>
  );
};

export default HeatingOrCooling;
