import React, { useState } from 'react';
import EmailInput from './shared/emailInput';
import TextInput from './shared/textInput';
import mailWhiteIcon from '../icons/mail_white.svg';
import descriptionWhiteIcon from '../icons/description_white.svg';
import { useIntl } from 'react-intl';
import { config } from '../config';

const Report = (props) => {
    const intl = useIntl();

    const [asEmail, setAsEmail] = useState(false);

    return (
        <div className="report">
            <h1>
                {intl.formatMessage({ id: 'completedyourestimations', defaultMessage: 'FÄRDIG MED ERA BERÄKNINGAR? Välj nedan för att skicka er beräkning som offertförfrågan eller endast beräkning (flik 2) till er egen mail.' })}
            </h1>
            <hr />
            <div className={'select-button-container'} id='form-anchor'>
                <div className={'form-wrapper'}>
                    <a className={!asEmail ? 'active btn-primary tab-buttons' : 'btn-primary tab-buttons'} onClick={() => { setAsEmail(false); props.onChange({target: {name: "results_or_offer", value: "offer"}}); }}>
                        <img src={descriptionWhiteIcon} alt="Skicka offert ikon" />
                        {intl.formatMessage({
                            id: 'sendresultasquotation',
                            defaultMessage: 'SKICKA RESULTAT SOM OFFERTFÖRFRÅGAN',
                        })}
                    </a>
                    <a className={asEmail ? 'active btn-primary tab-buttons' : 'btn-primary tab-buttons'} onClick={() => { setAsEmail(true); props.onChange({target: {name: "results_or_offer", value: "results"}}); }}>
                        <img src={mailWhiteIcon} alt="Skicka resultat ikon" />
                        {intl.formatMessage({ id: 'sendresultbyemail', defaultMessage: 'SKICKA RESULTAT VIA E-POST' })}
                    </a>
                    <div className={'email-form-wrapper'}>
                        {asEmail && (
                            <SendResult
                                email={props.email}
                                onChange={props.onChange}
                                send={props.send}
                                emailSent={props.emailSent}
                                emailSentError={props.emailSentError}
                                emailIsSending={props.emailIsSending}
                            />
                        )}
                        {!asEmail && (
                            <SendOffer
                                email={props.email}
                                offerName={props.offerName}
                                offerCompany={props.offerCompany}
                                offerCustomerNumber={props.offerCustomerNumber}
                                offerLocation={props.offerLocation}
                                offerPhoneNumber={props.offerPhoneNumber}
                                offerInformation={props.offerInformation}
                                onChange={props.onChange}
                                send={props.send}
                                emailSent={props.emailSent}
                                emailSentError={props.emailSentError}
                                emailIsSending={props.emailIsSending}
                            />
                        )}
                        {props.errorEmail && (
                            <div className="invalid-input">
                                {intl.formatMessage({ id: 'erroremail', defaultMessage: 'Felaktig epost' })}
                            </div>
                        )}
                    </div>
                </div>
                {!props.noResult && (
                    <a
                        className="btn-primary"
                        href={config.CART_API_URL + props.getSendToCartUrl()}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={descriptionWhiteIcon} alt="Skicka offert ikon" />
                        {intl.formatMessage({
                            id: 'addToCart',
                            defaultMessage: 'LÄGG I VARUKORG',
                        })}
                    </a>
                )}

            </div>            
        </div>
    );
};

const SendResult = (props) => {
    const intl = useIntl();

    return (
        <>
            <div className="send-as-email">
                {!props.emailSent && (
                    <>
                        <EmailInput
                            id="reid"
                            label={intl.formatMessage({ id: 'email', defaultMessage: 'E-post' })}
                            value={props.email}
                            name="result_email"
                            onChange={props.onChange}
                        />
                        <button className="btn-primary" type="button" onClick={props.send} disabled={props.emailIsSending}>
                            {' '}
                            {intl.formatMessage({ id: 'send', defaultMessage: 'SKICKA' })}
                        </button>
                    </>
                )}
                {props.emailSent && !props.emailSentError && (
                    <div>
                        {'   '}
                        {intl.formatMessage({ id: 'emailSent', defaultMessage: 'Tack, epost har skickats' })}
                    </div>
                )}

                {!props.emailSent && props.emailSentError && (
                    <div className="invalid-input">
                        {'   '}
                        {intl.formatMessage({
                            id: 'emailSentError',
                            defaultMessage: 'Något gick fel, vänligen försök igen senare',
                        })}
                    </div>
                )}
            </div>
            {intl.formatMessage({ id: 'pdfgdpr', defaultMessage: 'gdpr' })}
        </>
    );
};

const SendOffer = (props) => {
    const intl = useIntl();

    return (
        <>
            <div className="send-as-inquiry">
                {!props.emailSent && (
                    <>
                        <EmailInput
                            id="oeid"
                            label={intl.formatMessage({ id: 'email', defaultMessage: 'E-post' })}
                            value={props.email}
                            name="offer_email"
                            onChange={props.onChange}
                        />
                        <fieldset>
                            <TextInput
                                id="onid"
                                label={intl.formatMessage({ id: 'name', defaultMessage: 'Name' })}
                                value={props.offerName}
                                name="offer_name"
                                onChange={props.onChange}
                            />
                            <TextInput
                                id="onid"
                                label={intl.formatMessage({ id: 'company', defaultMessage: 'Företag' })}
                                value={props.offerCompany}
                                name="offer_company"
                                onChange={props.onChange}
                            />
                            <TextInput
                                id="onid"
                                label={intl.formatMessage({ id: 'customernumber', defaultMessage: 'Kundnummer' })}
                                optional={true}
                                value={props.offerCustomerNumber}
                                name="offer_customer_number"
                                onChange={props.onChange}
                            />
                            <TextInput
                                id="onid"
                                label={intl.formatMessage({ id: 'city', defaultMessage: 'Ort' })}
                                value={props.offerLocation}
                                name="offer_location"
                                onChange={props.onChange}
                            />
                            <TextInput
                                id="onid"
                                label={intl.formatMessage({ id: 'phonenumber', defaultMessage: 'Telefonnummer' })}
                                value={props.offerPhoneNumber}
                                name="offer_phone_number"
                                onChange={props.onChange}
                            />
                            <TextInput
                                id="onid"
                                label={intl.formatMessage({ id: 'informatiom', defaultMessage: 'Övrig information' })}
                                value={props.offerInformation}
                                name="offer_information"
                                onChange={props.onChange}
                                textArea
                            />
                        </fieldset>
                        <button className="btn-primary" type="button" onClick={props.send} disabled={props.emailIsSending}>
                            {intl.formatMessage({ id: 'send', defaultMessage: 'SKICKA' })}
                        </button>
                    </>
                )}
                {props.emailSent && !props.emailSentError && (
                    <div>
                        {'   '}
                        {intl.formatMessage({ id: 'emailSent', defaultMessage: 'Tack, epost har skickats' })}
                    </div>
                )}

                {!props.emailSent && props.emailSentError && (
                    <div className="invalid-input">
                        {'   '}
                        {intl.formatMessage({
                            id: 'emailSentError',
                            defaultMessage: 'Något gick fel, vänligen försök igen senare',
                        })}
                    </div>
                )}
            </div>
            {intl.formatMessage({ id: 'pdfgdpr', defaultMessage: 'gdpr' })}
        </>
    );
};

export default Report;
