import React from 'react';
import { useIntl } from "react-intl";
import infoIcon from '../../../icons/info.svg';

const Records = props => {
  const intl = useIntl();
  return (
    <>
      <h2>{intl.formatMessage({ id: "estimateddata", defaultMessage: "Beräknade uppgifter" })}</h2>
      <div className='calculating_data'>
        <table>
          <thead>
            <tr>
              <th><h3>{intl.formatMessage({ id: "expansion", defaultMessage: "Utvidgning" })}</h3></th>
              <th><h3>{props.vesselType === "1" ?  intl.formatMessage({ id: "prepressure", defaultMessage: "Förtryck (P0)" }) : intl.formatMessage({ id: "operatingpressure", defaultMessage: "Drifttryck" })}</h3></th>
              <th><h3>{intl.formatMessage({ id: "expansionvolume", defaultMessage: "Expansionsvolym" })}</h3></th>
              <th><h3>{intl.formatMessage({ id: "openingpressure", defaultMessage: "Öppningstryck (Psv)" })}</h3></th>
              {props.vesselType === "1" &&  
              <th>
                <h3>{intl.formatMessage({ id: "fillingpressure", defaultMessage: "Påfyllnadstryck" })}</h3>
                  
              </th>}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{(props.values && props.values.expansion ? `${props.values.expansion} %` : '-')}</td>
              <td>{(props.values && props.values.prePressure ? `${props.values.prePressure} bar` : '-')}</td>
              <td>{(props.values && props.values.expansionVolume ? `${props.values.expansionVolume} l` : '-')}</td>
              <td>{(props.values && props.values.openingPressure  ? `${props.values.openingPressure} bar` : '-')}</td>
              {props.vesselType === "1" && <td>{(props.values && props.values.prePressure  ? `${props.values.prePressure + 0.3} bar ` : '- ')}
                <span className='info-icon left'>
                  <span>Under förutsättning att media håller temperatur motsvarande systemets temperatur.</span>
                  <img src={infoIcon} alt={props.alt} className={'input-icon-info'} />
                </span></td>}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Records;
