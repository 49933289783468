import React from 'react';

const EmailInput = (props) => {
  return (
    <fieldset>
      <label htmlFor={props.id}>{props.label}</label>
      <input 
        type="email"
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={props.onChange} />
    </fieldset>
  );
};

export default EmailInput;