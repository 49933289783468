import React from 'react';
import addIcon from '../../../icons/add.svg';
import NumberInput from '../../shared/numberInput';
import NumberInputWithInfo from '../../shared/numberInputWithInfo';
import closeIcon from './../../../icons/close.svg';
import { useIntl } from "react-intl";

const Circuits = props => {
 
  return (
    <>
      {props.circuits.map((c, i) => (
        <Circuit
          key={'circuit_' + i}
          index={i}
          effect={c.effect}
          supplyTemp={c.supplyTemp}
          returnTemp={c.returnTemp}
          deleteCircuit={props.deleteCircuit}
          onChange={props.onChange}
          errors={props.errors}
          selectedSystemType={props.selectedSystemType}
        />
      ))}
      {props.circuits.length < 3 && (
        <AddCircuit addCircuit={props.addCircuit} />
      )}
    </>
  );
}

const Circuit = props => {
  const intl = useIntl();
  return (
    <div className='new_circuit'>
      <header className='box-header'>
        { intl.formatMessage({ id: "circuit", defaultMessage: "Krets" }) + " " + (props.index + 1)}
        {props.index > 0 && (
          <button
            className='close-button'
            onClick={() => props.deleteCircuit(props.index)}>
            <img src={closeIcon} alt='ikon på knapp för att ta bort en krets'></img>
          </button>
        )}
      </header>
      <section>
        <fieldset className='input_flex'>
          <NumberInputWithInfo
            id={'circuit_' + props.index + '_effect'}
            label={intl.formatMessage({ id: "effect", defaultMessage: "Effekt" })}
            alt='Effekt infoikon'
            infoText={intl.formatMessage({ id: "effectinfo", defaultMessage: "Vid dimensionering av expansionskärl med kompressor eller pump är det viktigt att rätt effekt anges för att Varmber skall kunna ta hänsyn till lastväxlingsflödet vid val av tryckhållnings enhet."})}
            name={'circuit_effect_' + (props.index + 1)}
            value={props.effect}
            onChange={props.onChange}
            errors={props.errors}
            suffix='effect'
          />
        </fieldset>
        <fieldset>
          <div>
            <NumberInput
              id={'circuit_' + props.index + '_supply-temp'}
              label={intl.formatMessage({ id: "supplytemp", defaultMessage: "Tillopstemp." })}
              name={'circuit_supply-temp_' + (props.index + 1)}
              value={props.supplyTemp}
              onChange={props.onChange}
              errors={props.errors}
              suffix='inlet_temperature'
              step={1} />
          </div>
          <div>
            <NumberInputWithInfo
              id={'circuit_' + props.index + '_return-temp'}
              label={intl.formatMessage({ id: "returntemp", defaultMessage: "Returtemp." })}
              infoText={props.selectedSystemType === 'cooling' ? intl.formatMessage({ id: "returntempinfo", defaultMessage: "OBS! Antingen bör beräkning göras efter worst-case (ingen kylaproduktion, anläggning uppnår omgivningstemperatur) alternativt att ni har lösning för detta fall installerad."}) : undefined}
              name={'circuit_return-temp_' + (props.index + 1)}
              value={props.returnTemp}
              onChange={props.onChange}
              errors={props.errors}
              suffix='return_temperature'
              step={1}
            />
          </div>
        </fieldset>
        <div></div>
      </section>
    </div>
  );
}

const AddCircuit = props => {
  const intl = useIntl();
  return (
    <div className='add_circuit'>
      <div
        className='button_wrapper'
        onClick={e => { 
          e.preventDefault();
          props.addCircuit(e);
        }}>
        <button className='add_circuit_button'>
          <img
            src={addIcon}
            alt='Infoikon för att lägga till krets'
            className='addIconStyle'
            id='acbid'
          />
        </button>
        <span className='link'>{intl.formatMessage({ id: "addnew", defaultMessage: "Lägg till ny" })}</span>
      </div>
    </div>
  );
}

export default Circuits;