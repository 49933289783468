import React from 'react';
import FormError from './formError';
import infoIcon from '../../icons/info.svg';

const SelectWithInfo = props => {
  return (
    <div className="select-with-info-container">
      <label htmlFor={props.id}>
        {props.label}

        {props.infoText && (
          <span className='info-icon'>
            <span>{props.infoText}</span>
            <img src={infoIcon} alt={props.alt} className={'input-icon-info'} />
          </span>
        )}
      </label>
      <select
        name={props.name}
        id={props.id}
        value={props.value ? props.value : -1}
        onChange={props.onChange}
        aria-describedby={
          props.errors && props.errors[props.name]
            ? props.name + '_error'
            : ''
        }
      >
        {props.placeholder && (
          <option value={-1} disabled>
            {props.placeholder}
          </option>
        )}
        {props.options.map((o, i) => (
          <option value={i + 1} key={props.id + '_option_' + (i + 1)}>
            {o}
          </option>
        ))}
      </select>
      {props.errors && <FormError errors={props.errors} name={props.name} />}
    </div>
  );
};

export default SelectWithInfo;
