import React from "react";
import NumberInput from "../../shared/numberInput";
import SelectWithInfo from "../../shared/selectWithInfo";
import { useIntl, FormattedMessage } from "react-intl";

const Fluid = props => {
  const intl = useIntl();
  return (
    <div className="fluid-container">
      <div className="fluid-type">
        <SelectWithInfo
          id="ftid"
          label={<FormattedMessage id="typeoffluid" defaultMessage="Typ av fluid" />}
          alt="Fluidtyp infoikon"
          infoText={
            <FormattedMessage id="typeoffluidinfo" defaultMessage="Ange vilken typ av fluid som är i systemet." />
          }
          name="fluid_type"
          options={[
            intl.formatMessage({ id: "water", defaultMessage: "Vatten" }),
            intl.formatMessage({ id: "ethyleneglycol", defaultMessage: "Etylenglykol (Max 50%)" }),
            intl.formatMessage({ id: "propylene", defaultMessage: "Propylenglykol (Max 50%)" }),
            intl.formatMessage({ id: "ethanol", defaultMessage: "Etanol (Max 30%)" })
          ]}
          value={props.fluidType}
          onChange={props.onChange} />
      </div>
      <div>        
        <NumberInput
          id="cid"
          label={intl.formatMessage({ id: "concentration", defaultMessage: "Koncentration" })}
          className="percentage"
          placeholder="100"
          name="fluid_concentration"
          value={props.fluidConcentration}
          onChange={props.onChange}
          errors={props.errors}
          suffix="concentration"
        />
      </div>
    </div>
  );
};

export default Fluid;
