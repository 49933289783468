const volumeFunctions = {
  volumeRequestValuesHaveChanged() {
    let changed = false;
    if (this.state.sentNumberOfCircuits_volume && this.state.circuits.length !== this.state.sentNumberOfCircuits_volume) {
      changed = true;
    } else if (
      ((this.state.sentPipeType && this.state.pipeType !== this.state.sentPipeType) || 
      (!this.state.sentPipeType && this.state.pipeType)) && this.state.pipeLength
    ) {
      changed = true;
    } else if (
      ((this.state.sentPipeLength && this.state.pipeLength !== this.state.sentPipeLength) || 
      (!this.state.sentPipeLength && this.state.pipeLength)) && this.state.pipeType
    ) {
      changed = true;
    } else if (
      (this.state.sentManualAddend && this.state.manualAddend !== this.state.sentManualAddend) || 
      (!this.state.sentManualAddend && this.state.manualAddend)
    ) {
      changed = true;
    } else {
      this.state.circuits.forEach(c => {
        if (
          (c.sentEffect_volume && c.effect !== c.sentEffect_volume) ||
          (!c.sentEffect_volume && c.effect)
        ) {
          changed = true;
        } else if (
          (c.sentSupplyTemp_volume && c.supplyTemp !== c.sentSupplyTemp_volume) ||
          (!c.sentSupplyTemp_volume && c.supplyTemp)
        ) {
          changed = true;
        } else if (
          (c.sentReturnTemp_volume && c.returnTemp !== c.sentReturnTemp_volume) ||
          (!c.sentReturnTemp_volume && c.returnTemp)
        ) {
          changed = true;
        } else if ((c.sentType && c.type !== c.sentType) || (!c.sentType && c.type)) {
          changed = true;
        }
      });
    }

    return changed;
  },

  noErrorsForVolume() {
    let hasErrors =
      this.state.errors['circuit_effect_1'] ||
      this.state.errors['circuit_effect_2'] ||
      this.state.errors['circuit_effect_3'] ||
      this.state.errors['circuit_supply-temp_1'] ||
      this.state.errors['circuit_supply-temp_2'] ||
      this.state.errors['circuit_supply-temp_3'] ||
      this.state.errors['circuit_return-temp_1'] ||
      this.state.errors['circuit_return-temp_2'] ||
      this.state.errors['circuit_return-temp_3'] ||
      this.state.errors['circuit_type_1'] ||
      this.state.errors['circuit_type_2'] ||
      this.state.errors['circuit_type_3'] ||
      this.state.errors['pipe_type'] ||
      this.state.errors['pipe_length'] ||
      this.state.errors['manual_addend'];

    return !hasErrors;
  },

  hasCompleteCircuitsForVolume() {
    let valueIsMissing = false;
    this.state.circuits.forEach(c => {
      if (!c.effect || !c.supplyTemp || !c.returnTemp || !c.type) {
        valueIsMissing = true;
      }
    });

    return !valueIsMissing;
  },

  volumeRequestHasBeenSent() {
    let sent = false;
    if (this.state.sentNumberOfCircuits_volume) {
      sent = true;
    } else if (this.state.sentPipeType) {
      sent = true;
    } else if (this.state.sentPipeLength) {
      sent = true;
    } else if (this.state.sentManualAddend) {
      sent = true;
    } else {
      this.state.circuits.forEach(c => {
        if (c.sentEffect_volume) {
          sent = true;
        } else if (c.sentSupplyTemp_volume) {
          sent = true;
        } else if (c.sentReturnTemp_volume) {
          sent = true;
        } else if (c.sentType) {
          sent = true;
        }
      });
    }

    return sent;
  },

  requestVolume() {
    this.setState(prevState => {
      let circuits = [...prevState.circuits];
      circuits.forEach(c => {
        c.sentEffect_volume = c.effect;
        c.sentSupplyTemp_volume = c.supplyTemp;
        c.sentReturnTemp_volume = c.returnTemp;
        c.sentType = c.type;
      })

      return {
        working: true,
        circuits: circuits,
        sentPipeType: prevState.pipeType,
        sentPipeLength: prevState.pipeLength,
        sentManualAddend: prevState.manualAddend,
        sentNumberOfCircuits_volume: prevState.circuits.length
      }
    }, this.getVolume())
  },

  getVolume() {
    let url = new URL(this.state.rootUrl + 'varmber/volume');
    let params = { 
      circuits: JSON.stringify(this.state.circuits.map((c) => ({ 
        effectValue: c.effect, 
        supplyTemp: c.supplyTemp, 
        returnTemp: c.returnTemp 
      }))), 
      circuitDetails: JSON.stringify(this.state.circuits.map((c) => ({
        type: c.type 
      }))), 
      culvert: JSON.stringify({ 
        pipeType: this.state.pipeType, 
        length: this.state.pipeLength 
      })
    };
    if (this.state.manualAddend) {
      params.manualAddend = this.state.manualAddend;
    }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    
    fetch(url).then(response => {
      if (!response.ok) {
        throw Error();
      }
      
      return response.json();
    }).then((response) => {
      this.setState(
        prevState => {
          let cs = [...prevState.circuits];
          response.circuitVolumes.forEach((cv, i) => {
            let c = cs[i];
            c.volume = cv;
          });

          return { totalSystemVolume: response.totalSystemVolume, circuits: cs };
        }, () => this.clearError('fetch_volume', () => this.tryFetch())
      );
    }).catch(() => {      
      this.setState(
        prevState => { 
          let cs = [...prevState.circuits];
          for (var i = 0; i < cs.length; i++) {
            cs[i].volume = '';
          }

          return { circuits: cs, totalSystemVolume: '' }
        }, 
        () => this.setError('fetch_volume', this.props.intl.formatMessage({id: 'estimatevolumeerror', defaultMessage: "Kunde inte hämta beräknad volym, ange manuellt eller försök igen"}))
      );
    }).finally(() => {
      this.setState({ working: false })
    })
  }
};

export default volumeFunctions;