import React from 'react';
import error from '../../icons/error.svg';

const FormError = props => {
  return (
    <>
      {props.errors && props.errors[props.name] && (
          <span className='error-icon' aria-live='assertive' id={props.name + '_error'}>
            <span>{props.errors[props.name]}</span>
            <img
              src={error}
              alt={props.alt}
              className={'input-icon-error'}
            />
          </span>
      )}
      </>
  );
};

export default FormError;
