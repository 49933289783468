import React from 'react';
import addIcon from '../../icons/add-black.svg';
import subtractIcon from '../../icons/subtract.svg';
import VesselCategoryForm from './vesselCategoryForm';
import { FormattedMessage } from 'react-intl';

const VesselCategory = props => {
  return (
    <>
      <button
        onClick={props.toggleExpansion}
        className={props.expanded ? 'expansion-bar active' : 'expansion-bar'}>
        {props.expanded && (
          <img
            src={subtractIcon}
            alt='Kollapsa expansionskärl-kategorin'
            className='iconStyle' />
        )}
        {!props.expanded && (
          <img
            src={addIcon}
            alt='Expandera expansionskärl-kategorin'
            className='iconStyle' />
        )}
        <h3><FormattedMessage id="expansiontank" defaultMessage="Expansionkärl" /></h3>
      </button>

      {props.expanded && (
        <VesselCategoryForm
          visible={props.expanded}
          working={props.working}
          addCircuit={props.addCircuit}
          deleteCircuit={props.deleteCircuit}
          manualVolumeCalculation={props.manualVolumeCalculation}
          setManualVolumeCalculation={props.setManualVolumeCalculation}
          culvertExpanded={props.culvertExpanded}
          toggleCulvertExpansion={props.toggleCulvertExpansion}
          manualAddendExpanded={props.manualAddendExpanded}
          toggleManualAddendExpanded={props.toggleManualAddendExpanded}
          objectName={props.objectName}
          staticHeight={props.staticHeight}
          openingPressure={props.openingPressure}
          selectedSystemType={props.selectedSystemType}
          fluidType={props.fluidType}
          fluidConcentration={props.fluidConcentration}
          circuits={props.circuits}
          pipeType={props.pipeType}
          pipeLength={props.pipeLength}
          manualAddend={props.manualAddend}
          totalSystemVolume={props.totalSystemVolume}
          vesselType={props.vesselType}
          errors={props.errors}
          onChange={props.onChange}
          records={props.records}
          results={props.results}
          alternativeOneSelected={props.alternativeOneSelected}
          alternativeTwoSelected={props.alternativeTwoSelected}
          selectAlternativeOne={props.selectAlternativeOne}
          selectAlternativeTwo={props.selectAlternativeTwo}
          clear={props.clear} 
          objectNameRef={props.objectNameRef}
          />

      )}
    </>
  );
};

export default VesselCategory;
