import React from 'react';
import Select from '../../shared/select';
import NumberInputWithInfo from '../../shared/numberInputWithInfo';
import SelectWithInfo from '../../shared/selectWithInfo';
import pipeTypes from '../../shared/pipe';
import SafetyValves from './safetyValves';
import ZeroFlow from './zeroFlow';
import TypeOfSystem from './typeOfSystem';
import closeIcon from './../../../icons/close.svg';
import addIcon from '../../../icons/add.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { config } from '../../../config';

const Constructions = props => {
    const intl = useIntl();

    const getOptions = () => {
        let options = [];
        if (config.HIDE_BOILERS === 'false') {
            options.push(intl.formatMessage({ id: 'boiler', defaultMessage: 'Panna' }));
        }

        options.push(
            ...[
                intl.formatMessage({ id: 'heatexchanger', defaultMessage: 'Värmeväxlare' }),
                intl.formatMessage({ id: 'heatpump', defaultMessage: 'Värmepump' }),
            ],
        );

        return options;
    };

    return (
        <>
            <fieldset>
                <Select
                    id={'ctid'}
                    label={<FormattedMessage id="constructiontype" defaultMessage="Anläggningstyp" />}
                    placeholder={
                        props.type ? null : intl.formatMessage({ id: 'choosetype', defaultMessage: 'Välj typ' })
                    }
                    name={'constructions_type'}
                    value={props.type}
                    options={getOptions()}
                    onChange={props.onChange}
                    startIndex={config.HIDE_BOILERS === 'true' ? 1 : 0}
                />
            </fieldset>
            {props.type && props.type !== '3' && (
                <div className="add_circuit_wrapper">
                    {props.constructions.map((c, i) => (
                        <Construction
                            key={'construction_' + i}
                            deleteConstruction={props.deleteConstruction}
                            index={i}
                            type={props.type}
                            effect={c.effect}
                            pipeType={c.pipeType}
                            boilerType={c.boilerType}
                            errors={props.errors}
                            onChange={props.onChange}
                        />
                    ))}
                    {props.type && props.constructions.length < 3 && (
                        <AddConstruction addConstruction={props.addConstruction} />
                    )}
                </div>
            )}
            {props.type === '3' && (
                <fieldset>
                    <Select
                        id={'nrhpid'}
                        label={<FormattedMessage id="numberofheatpumps" defaultMessage="Antal värmepumpar" />}
                        name={'heat-pump_amount'}
                        value={props.heatPumpAmount}
                        options={['1', '2', '3']}
                        onChange={props.onChange}
                    />
                </fieldset>
            )}
            {props.type === '1' && (
                <>
                    <fieldset>
                        <TypeOfSystem typeOfSystem={props.typeOfSystem} onChange={props.onChange} />
                    </fieldset>
                    <fieldset>
                        <SafetyValves safetyValveDirect={props.safetyValveDirect} onChange={props.onChange} />
                    </fieldset>

                    <fieldset>
                        <ZeroFlow zeroFlowApproved={props.zeroFlowApproved} onChange={props.onChange} />
                    </fieldset>
                </>
            )}
        </>
    );
};

const AddConstruction = props => {
    const intl = useIntl();
    return (
        <div className="add_circuit">
            <div
                className="button_wrapper"
                onClick={e => {
                    e.preventDefault();
                    props.addConstruction(e);
                }}
            >
                <button className="add_circuit_button">
                    <img src={addIcon} alt="Infoikon för att lägga till krets" className="addIconStyle" id="acbid" />
                </button>
                <span className="link">{intl.formatMessage({ id: 'addnew', defaultMessage: 'Lägg till ny' })}</span>
            </div>
        </div>
    );
};

const Construction = props => {
    const renderSwitch = type => {
        switch (type) {
            case '1': {
                return (
                    <Boiler
                        deleteConstruction={props.deleteConstruction}
                        index={props.index}
                        effect={props.effect}
                        pipeType={props.pipeType}
                        type={props.boilerType}
                        errors={props.errors}
                        onChange={props.onChange}
                    />
                );
            }
            case '2': {
                return (
                    <HeatExchanger
                        deleteConstruction={props.deleteConstruction}
                        index={props.index}
                        effect={props.effect}
                        errors={props.errors}
                        onChange={props.onChange}
                    />
                );
            }
            default:
            // console.error('Unknown construction type');
        }
    };

    return <>{renderSwitch(props.type)}</>;
};

const Boiler = props => {
    const intl = useIntl();

    return (
        <div className="new_circuit">
            <header className="box-header">
                {intl.formatMessage({ id: 'boiler', defaultMessage: 'Panna' }) + ' ' + (props.index + 1)}
                {props.index > 0 && (
                    <button
                        className="close-button"
                        onClick={e => {
                            e.preventDefault();
                            props.deleteConstruction(props.index);
                        }}
                    >
                        <img src={closeIcon} alt="ikon på knapp för att ta bort en krets"></img>
                    </button>
                )}
            </header>
            <section>
                <fieldset className="input_flex">
                    <NumberInputWithInfo
                        id={'boiler_' + props.index + '_effect'}
                        label={intl.formatMessage({ id: 'effect', defaultMessage: 'Effekt' })}
                        alt="Infoikon för pannas effekt"
                        name={'construction_effect_' + (props.index + 1)}
                        value={props.effect}
                        errors={props.errors}
                        onChange={props.onChange}
                        suffix="effect"
                    />
                </fieldset>
                <fieldset>
                    <div>
                        <Select
                            id={'boiler_' + props.index + '_construction-type'}
                            label={intl.formatMessage({ id: 'pipetype', defaultMessage: 'Framledning' })}
                            name={'construction_pipe-type_' + (props.index + 1)}
                            placeholder="Välj"
                            value={props.pipeType}
                            options={pipeTypes}
                            onChange={props.onChange}
                        />
                    </div>
                    <div>
                        <SelectWithInfo
                            id={'boiler_' + props.index + '_type'}
                            label="Typ av panna"
                            alt="Infoikon för typ av panna"
                            name={'construction_boiler-type_' + (props.index + 1)}
                            options={[
                                intl.formatMessage({ id: 'oilelectricitygas', defaultMessage: 'Olja, el, gas' }),
                                intl.formatMessage({ id: 'solidfuel', defaultMessage: 'Fastbränsle' }),
                            ]}
                            value={props.type}
                            onChange={props.onChange}
                        />
                    </div>
                </fieldset>
                <div></div>
            </section>
        </div>
    );
};

const HeatExchanger = props => {
    const intl = useIntl();

    return (
        <div className="new_circuit">
            <header className="box-header">
                {intl.formatMessage({ id: 'heatexchanger', defaultMessage: 'Värmeväxlare' }) + ' ' + (props.index + 1)}
                {props.index > 0 && (
                    <button
                        className="close-button"
                        onClick={e => {
                            e.preventDefault();
                            props.deleteConstruction(props.index);
                        }}
                    >
                        <img src={closeIcon} alt="ikon på knapp för att ta bort en krets"></img>
                    </button>
                )}
            </header>
            <section>
                <fieldset className="input_flex">
                    <NumberInputWithInfo
                        id={'heat-exchanger_' + props.index + '_effect'}
                        label={intl.formatMessage({ id: 'effect', defaultMessage: 'Effekt' })}
                        alt="Infoikon för värmeväxlares effekt"
                        name={'construction_effect_' + (props.index + 1)}
                        value={props.effect}
                        errors={props.errors}
                        onChange={props.onChange}
                        suffix="effect"
                    />
                </fieldset>
            </section>
        </div>
    );
};

export default Constructions;
