import React from 'react';
import Introduction from './components/introduction';
import Umbrella from './components/umbrella';

require('dotenv').config();

function App(props) { 
  return (
    <>
      <div className='main_wrapper'>
        <Introduction />
        <Umbrella env={process.env.NODE_ENV} />
      </div>
    </>
  );
}

export default App;
